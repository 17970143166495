import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { truncateWalletString } from '../../utils';
import toast from "react-hot-toast";
import Explorer from '../Explore/Explorer';
import Collections from '../Collections/Collections';
import './myProfile.scss'

function MyProfile(props) {
    const { connector, library, account, active, chainId } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    let newVariable = process.env.REACT_APP_NETWORK_ID;
    useEffect(() => {
        const isLoggedin =
            account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId]);

    const location = useLocation();
    const userAccount = location.pathname.split("/")[2];
    const [user, setUser] = useState<any>();
    useEffect(() => {
        if (userAccount)
            axios.get(`/api/user/${userAccount}`).then((res) => {
                setUser(res.data.user);
            })
    }, [userAccount]);
    const [copied, setCopied] = useState(false);
    const copyHandle = () => {
        if (!loginStatus) {
            toast.error("Please connect your wallet.");
            return;
        }
        let textarea = document.createElement("textarea");
        textarea.textContent = userAccount;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter");
        } finally {
            toast.success("Copied to Clipboard");
            document.body.removeChild(textarea);
            setCopied(true);

        }

    };
    
    return (
        <>
            {/* Breadcrumb  */}
            {/* <section
                style={{
                    background: (user?.banner_url && user?.banner_url !== "") ? `url(${user?.banner_url}) no-repeat scroll top center/cover ` :
                        'url(/img/inner_bg.jpg) scroll top center / cover '
                }}
                className="breadcrumb-area overlay-dark d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content text-center">
                                <h2 className="m-0">Profile</h2>
                                <ol className="breadcrumb d-flex justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Profile</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="myProfile author-area activity-area popular-collections-area">
                <div className="row">
                        <div className="top">
                            {/* Author Profile */}
                            <div className="top-div card overlay-dark no-hover text-center"
                             style={{
                                background: (user?.banner_url && user?.banner_url !== "") ? `url(${user?.banner_url}) no-repeat scroll top center/cover ` :
                                    'url(/img/inner_bg.jpg) scroll top center / cover '
                            }}
                            >
                                <div className="avatar-div">
                                    <img className="rounded-circle profile-div" src={user?.logo_url} alt="" />
                                </div>
                                {/* Card Caption */}
                                <div className="user-info p-0">
                                    {/* Card Body */}
                                    <div className="card-body">
                                        <p className="mb-2">Author Profile</p>
                                        <h5 className="mb-3">{user?.name}</h5>
                                        {user?.bio && 
                                        <p className="my-3">{user?.bio}</p>
                                        }
                                        
                                        <div className="social-links">
                                        <div className="social-icons d-flex justify-content-center">
                                        {/* <a target="_blank" href={user?.social_discord_link} className={user?.social_discord_link}>
                                                        <i className="fab fa-discord" />
                                                        <i className="fab fa-discord" />
                                                    </a>
                                                    <a target="_blank" href={user?.social_discord_link} className={user?.social_discord_link}>
                                                        <i className="fab fa-discord" />
                                                        <i className="fab fa-discord" />
                                                    </a> */}
                                                {
                                                    user?.social_discord_link && <a target="_blank" href={user?.social_discord_link} className={user?.social_discord_link}>
                                                        <i className="fab fa-discord" />
                                                        <i className="fab fa-discord" />
                                                    </a>
                                                }
                                                {
                                                    user?.social_twitter_link && <a target="_blank" href={user?.social_twitter_link} className={user?.social_discord_link}>
                                                        <i className="fab fa-twitter" />
                                                        <i className="fab fa-twitter" />
                                                    </a>
                                                }
                                                {
                                                    user?.social_instagram_link && <a target="_blank" href={user?.social_instagram_link} className={user?.social_instagram_link}>
                                                        <i className="fab fa-chrome" />
                                                        <i className="fab fa-chrome" />
                                                    </a>
                                                }
                                        </div>
                                    </div>
                                        <div className="input-group">
                                            <p className="form-control" style={{padding:'0.5rem'}}>{truncateWalletString(user?.address)}</p>
                                            <div className="input-group-append">
                                                <button style={{ cursor: "pointer" }} onClick={copyHandle}><i className="icon-docs" /></button>
                                            </div>
                                        </div>
                                        {/* Social Icons */}
                                        
                                        {
                                            account?.toLowerCase() === user?.address && <Link className="editBtn btn btn-bordered-white btn-smaller" to="/edit-profile">Edit</Link>
                                        }

                                    </div>
                                    
                                    
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 mt-5">
                            <ul className="netstorm-tab nav nav-tabs" id="nav-tab">
                                    <li>
                                        <Link className={(location.hash === "" || location.hash === "#nav-items") && "active"} id="nav-items-tab" data-toggle="pill" to="#nav-items">
                                            <h5 className="m-0">NFT Items</h5>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={location.hash === "#nav-collections" && "active"} id="nav-collections-tab" data-toggle="pill" to="#nav-collections">
                                            <h5 className="m-0">Collections</h5>
                                        </Link>
                                    </li>
                                </ul>
                        </div>
                                
                        <div className="items row explore-items">
                            {(location.hash === "" || location.hash === "#nav-items") && 
                                <Explorer style={{ padding: 0, margin: 0 }} showMethod={2} {...props} />
                            }
                            {location.hash === "#nav-collections" && 
                                <Collections style={{ padding: 0, margin: 0 }} filter={1} {...props} />
                            }
                        </div>
                    </div>
            </section>
        </>

    )
}

export default MyProfile