import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import ConnectModal from "components/connectModal/ConnectModal";
import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { truncateWalletString } from "../../utils";
import { connectors, connectorLocalStorageKey } from "../../utils/connectors";
import './style.scss'
type MenuType = {
  expanded: boolean;
  setExpanded(flag: boolean): void;
};

const Header = ({ expanded, setExpanded }: MenuType) => {
  const { connector, library, account, active, chainId, deactivate } = useWeb3React();
  const [loginStatus, setLoginStatus] = useState(false);
  let newVariable = process.env.REACT_APP_NETWORK_ID;
  useEffect(() => {
    const isLoggedin = account && active && chainId === parseInt(newVariable, 10);
    setLoginStatus(isLoggedin);
  }, [connector, library, account, active, chainId, newVariable]);



  const [user, setUser] = useState(undefined);

  useEffect(() => {
    if (loginStatus && !user) {
      axios.get(`/api/user/${account}`)
        .then(async res => {
          setUser(res.data.user);
        })
        .catch(err => {
          setUser(undefined);
        })
    }
  }, [loginStatus, user, account]);


  const signOut = () => {
    connectors.map((connector) => {
      deactivate();
    })
    window.localStorage.setItem(connectorLocalStorageKey, "");
    window.location.href = "/";
  }
  const [showConnectModal, setShowConnectModal] = useState(false);

  return (
    <header id="header">
      {/* Navbar */}

      <nav
        data-aos="zoom-out"
        data-aos-delay={800}
        className="navbar navbar-expand"
      >
        <div className="header-div container header">
          {/* Navbar Brand*/}
          <NavLink className="navbar-brand" to="/">
            <img
              className="navbar-brand-sticky"
              src="/img/logo.png"
              alt="sticky brand-logo"
            />
          </NavLink>
          <div className="ml-auto" />
          {/* Navbar */}
          <ul className="navbar-nav items mx-auto">
            <li className="nav-item dropdown">
              <NavLink className="nav-link" to="/">
                Home
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/authors" className="nav-link">
                Authors
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink to="/explore" className="nav-link">
                Explore
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/collections" className="nav-link">
                Collections
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/activity" className="nav-link">
                Activity
              </NavLink>
            </li>

            {
              loginStatus &&
              <li className="nav-item dropdown">
                <a className="nav-link" href="/create">
                  Create <i className="fas fa-angle-down ml-1" />
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <NavLink to="/create" className="nav-link">
                      Item
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/collection/create" className="nav-link">
                      Collection
                    </NavLink>
                  </li>
                </ul>
              </li>
            }
            {loginStatus && <li className="nav-item">
              <NavLink to="/import" className="nav-link">
                Import
              </NavLink>
            </li>}
            {loginStatus && <li className="nav-item">
              <a href="https://epicmtv.com/" target="_blank" className="nav-link">
                Mint
              </a>
            </li>}
            {/* <li className="nav-item">
              <NavLink to="/contact" className="nav-link">
                Contact
              </NavLink>
            </li> */}
            {/* {
              loginStatus && <li className="nav-item dropdown">
                <NavLink to={'/profile/' + account} className="nav-link">
                  {
                    <img
                      src={user ? user?.logo_url : "/avatar.png"}
                      className="avatar-sm rounded-circle" alt="" />
                  }
                  <i className="fas fa-angle-down ml-1" />
                </NavLink>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <NavLink to={'/profile/' + account} className="nav-link">
                      {
                        <img
                          src={user ? user?.logo_url : "/avatar.png"}
                          className="avatar-sm rounded-circle" alt=""
                          style={{ width: '30px', height: '30px' }} />
                      }
                      Profile
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/myItems" className="nav-link">
                      <i className="fa fa-eye" />
                      My Items
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/myCollections" className="nav-link">
                      <i className="fa fa-th" />
                      My Collection
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <div onClick={() => signOut()} className="nav-link">
                      <i className="fa fa-sign-out-alt" />
                      SignOut
                    </div>
                  </li>
                </ul>
              </li>
            } */}

          </ul>
          {/* Navbar Toggler */}
          <ul className="navbar-nav toggle" onClick={() => setExpanded(true)}>
            <li className="nav-item">
              <div

                className="nav-link"
              >
                <i className="fas fa-bars toggle-icon m-0" />
              </div>
            </li>
          </ul>
          {/* Navbar Action Button */}


          {!loginStatus ? <ul className="navbar-nav action">
            <li className="nav-item ml-3 btn ml-lg-auto btn-bordered-white" onClick={() => setShowConnectModal(true)}>
              <i className="icon-wallet mr-md-2" />
              Wallet Connect
            </li>
          </ul> :
            <ul className="navbar-nav action">
              <li className="nav-item ml-3 dropdown" >
                <NavLink
                  to={'/profile/' + account}
                  className="wallet-btn btn ml-lg-auto btn-bordered-white"
                >
                  <img
                    src={user ? user?.logo_url : "/avatar.png"}
                    className="avatar-img avatar-sm rounded-circle" alt="" />
                  {truncateWalletString(account)}
                </NavLink>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <div onClick={() => signOut()} className="nav-link">
                      <i className="fa fa-sign-out-alt" />
                      SignOut
                    </div>
                  </li>
                </ul>
              </li>
            </ul>}
          {/* {
            loginStatus && <ul className="navbar-nav d-none d-md-block d-lg-block ">
              <li className="nav-item ml-3">
                <div className="btn ml-lg-auto btn-bordered-white">
                  {truncateWalletString(account)}
                </div>
              </li>
            </ul>
          } */}

        </div>
      </nav>
      <ConnectModal showConnectModal={showConnectModal} setShowConnectModal={setShowConnectModal} />
    </header>
  );
};

export default Header;
