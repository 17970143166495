import "@ethersproject/shims";
import { ethers } from "ethers";
import { getContractObj, getCollectionContract, getContractInfo } from ".";

export function isAddress(address) {
  try {
    ethers.utils.getAddress(address);
  } catch (e) {
    return false;
  }
  return true;
}

export function toEth(amount) {
  return ethers.utils.formatEther(String(amount));
}

export function toWei(amount) {
  return ethers.utils.parseEther(String(amount));
}
/**
 * NFT Contract Management
 */
export async function isNFTApprovedForMarket(
  collection,
  account,
  chainId,
  provider
) {
  const marketContract = getContractObj(
    "EpicGalleryMarket",
    chainId,
    provider
  );
  const nftToken = getCollectionContract(collection, chainId, provider);

  return await nftToken.isApprovedForAll(account, marketContract.address);
}
export async function isNFTApprovedForAuction(
  collection,
  account,
  chainId,
  provider
) {
  const auctionContract = getContractObj(
    "EpicGalleryAuction",
    chainId,
    provider
  );
  const nftToken = getCollectionContract(collection, chainId, provider);

  return await nftToken.isApprovedForAll(account, auctionContract.address);
}

export async function setNFTApprovalForMarket(
  collection,
  approved,
  chainId,
  provider
) {
  const marketContract = getContractObj(
    "EpicGalleryMarket",
    chainId,
    provider
  );
  const nftToken = getCollectionContract(collection, chainId, provider);
  try {
    const tx = await nftToken.setApprovalForAll(
      marketContract.address,
      approved
    );
    await tx.wait(1);
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
}
export async function setNFTApprovalForAuction(
  collection,
  approved,
  chainId,
  provider
) {
  const auctionContract = getContractObj(
    "EpicGalleryAuction",
    chainId,
    provider
  );
  const nftToken = getCollectionContract(collection, chainId, provider);
  try {
    const tx = await nftToken.setApprovalForAll(
      auctionContract.address,
      approved
    );
    await tx.wait(1);
    return true;
  } catch (e) {
    console.log(e);
  }
  return false;
}

export async function addItem(
  collection,
  uri,
  chainId,
  provider
) {
  const collectionContract = getCollectionContract(
    collection,
    chainId,
    provider
  );
  //const EpicGalleryNFTContractInfo = getContractInfo('EpicGalleryNFT', chainId);
  try {
    //let tx ;
    await collectionContract.mintTo(uri);
    // const receipt = await tx.wait(2);
    // if(receipt.confirmations) {
    //     const interf = new ethers.utils.Interface(EpicGalleryNFTContractInfo.abi);
    //     const logs = receipt.logs;
    //     let tokenId = 0;
    //     for(let index = 0; index < logs.length; index ++) {
    //       const log = logs[index];
    //       if(collection.toLowerCase() === log.address.toLowerCase()) {
    //         tokenId = interf.parseLog(log).args.tokenId.toNumber();
    //         return tokenId;
    //       }
    //     }
    // }
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function onTransfer(
  collection,
  fromAddress,
  toAddress,
  token_id,
  chainId,
  provider
) {
  const collectionContract = getCollectionContract(
    collection,
    chainId,
    provider
  );
  try {
    const tx = await collectionContract["safeTransferFrom(address,address,uint256)"](fromAddress, toAddress, token_id);
    const receipt = await tx.wait(2);
    return receipt.confirmations;
  } catch (e) {
    console.log(e);
    return false;
  }
}

/**
 * Market Contract Management
 */

export async function createNewCollection(
  chainId,
  provider
) {
  const marketContract = getContractObj(
    "EpicGalleryMarket",
    chainId,
    provider
  );
  const marketContractInfo = getContractInfo("EpicGalleryMarket", chainId);
  try {
    const tx = await marketContract.createCollection();
    const receipt = await tx.wait(2);
    if (receipt.confirmations) {
      const interf = new ethers.utils.Interface(marketContractInfo.abi);
      const logs = receipt.logs;
      let collectionAddress = "";
      for (let index = 0; index < logs.length; index++) {
        const log = logs[index];
        if (
          marketContractInfo.address.toLowerCase() === log.address.toLowerCase()
        ) {
          collectionAddress = interf
            .parseLog(log)
            .args.collection_address.toLowerCase();
          return collectionAddress;
        }
      }
    }
    return "";
  } catch (e) {
    console.log(e);
    return "";
  }
}

export async function listItem(
  collection,
  owner,
  token_id,
  creator,
  price,
  chainId,
  provider
) {
  const marketContract = getContractObj("EpicGalleryMarket", chainId, provider);
  const marketContractInfo = getContractInfo("EpicGalleryMarket", chainId);
  if (!marketContract || !marketContractInfo) return false;
  try {
    let isApproved = await isNFTApprovedForMarket(
      collection,
      owner,
      chainId,
      provider
    );
    if (!isApproved) {
      isApproved = await setNFTApprovalForMarket(
        collection,
        true,
        chainId,
        provider
      );
    }
    if (isApproved) {
      const tx = await marketContract.list(
        collection,
        token_id,
        ethers.constants.AddressZero,
        ethers.utils.parseEther(price),
        creator
      );
      const receipt = await tx.wait(2);
      if (receipt.confirmations) {
        return true;
        // const interf = new ethers.utils.Interface(marketContractInfo.abi);
        // const logs = receipt.logs;
        // let pairId  = 0;
        // for(let index = 0; index < logs.length; index ++) {
        //     const log = logs[index];
        //     if(marketContractInfo.address.toLowerCase() === log.address.toLowerCase()) {
        //         pairId = interf.parseLog(log).args.id.toString();
        //         return pairId;
        //     }
        // }
      }
      return false;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function delistItem(id, chainId, provider) {
  const marketContract = getContractObj("EpicGalleryMarket", chainId, provider);
  if (!marketContract) return false;
  try {
    const tx = await marketContract.delist(id);
    await tx.wait(2);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function buy(
  account,
  id,
  price,
  royalty,
  chainId,
  provider
) {
  const marketContract = getContractObj("EpicGalleryMarket", chainId, provider);
  if (!marketContract) return false;
  try {
    const tx = await marketContract.buy(id, royalty, { value: ethers.utils.parseEther(String(price)) });
    await tx.wait(2);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

/**
 * Auction Contract Management
 */
export async function createAuction(
  collection,
  owner,
  token_id,
  startPrice,
  startTime,
  endTime,
  chainId,
  provider
) {
  const auctionContract = getContractObj("EpicGalleryAuction", chainId, provider);
  const auctionContractInfo = getContractInfo("EpicGalleryAuction", chainId);
  if (!auctionContract || !auctionContractInfo) return false;
  try {
    let isApproved = await isNFTApprovedForAuction(
      collection,
      owner,
      chainId,
      provider
    );
    if (!isApproved) {
      isApproved = await setNFTApprovalForAuction(
        collection,
        true,
        chainId,
        provider
      );
    }
    if (isApproved) {
      const tx = await auctionContract.createAuction(
        collection,
        token_id,
        ethers.constants.AddressZero,
        ethers.utils.parseEther(startPrice),
        startTime,
        endTime
      );
      const receipt = await tx.wait(2);
      if (receipt.confirmations) {
        return true;
      }
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function finalizeAuction(id, chainId, provider) {
  const auctionContract = getContractObj("EpicGalleryAuction", chainId, provider);
  if (!auctionContract) return false;
  try {
    const tx = await auctionContract.finalizeAuction(id);
    await tx.wait(2);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function bidOnAuction(
  account,
  id,
  price,
  chainId,
  provider,
  balance
) {
  const auctionContract = getContractObj(
    "EpicGalleryAuction",
    chainId,
    provider
  );
  try {
    const tx = await auctionContract.bidOnAuction(
      id,
      ethers.utils.parseEther(String(price)),
      { value: ethers.utils.parseEther(String(price)) }
    );
    await tx.wait(2);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function mintFixed(chainId, provider, _tokenURI, _price, _royalty) {
  const nftContract = getContractObj('QUINTNFT', chainId, provider);
  const QUINTContract = getContractObj('QUINT', chainId, provider);
  try {
    const QUINTDecimals = await QUINTContract.decimals();
    const tx = await nftContract.mintFixed(_tokenURI, QUINTContract.address, _royalty, ethers.utils.parseUnits(_price.toString(), QUINTDecimals));
    await tx.wait(1);

    return tx.hash;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function mintAuction(chainId, provider, _tokenURI, _minBidPrice, _startTime: Date, _endTime: Date, _royalty) {
  const nftContract = getContractObj('QUINTNFT', chainId, provider);
  const QUINTContract = getContractObj('QUINT', chainId, provider);
  const startUnixTimeStamp = Math.round(_startTime.getTime() / 1000);
  const endUnixTimeStamp = Math.round(_endTime.getTime() / 1000);
  try {
    const QUINTDecimals = await QUINTContract.decimals();
    const tx = await nftContract.mintAuction(_tokenURI, QUINTContract.address, _royalty, ethers.utils.parseUnits(_minBidPrice.toString(), QUINTDecimals), startUnixTimeStamp, endUnixTimeStamp);
    await tx.wait(1);

    return tx.hash;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function mintUnlimitedAuction(chainId, provider, _tokenURI, _royalty) {
  const nftContract = getContractObj('QUINTNFT', chainId, provider);
  const QUINTContract = getContractObj('QUINT', chainId, provider);
  try {
    const tx = await nftContract.mintUnlimitedAuction(_tokenURI, QUINTContract.address, _royalty);
    await tx.wait(1);

    return tx.hash;
  } catch (e) {
    console.log(e);
    return false;
  }
}