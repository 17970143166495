import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './historyCard.scss'
type PropsType = {
    event ? :any
    loginStatus ? : boolean
    account ? : string
    rate ? : any
}
export default function HistoryCard({event, loginStatus, account, rate}:PropsType) {
    
  return (
    <li className="historyCard">
        <div className="avatar">
            <img className="avatar-sm rounded-circle mr-3" src={event?.user.logo_url || "/avatar.png"} alt="" />
            <span>
                <Link target="_blank" to={"/profile/" + event?.user.address}>
                    {event?.name}&nbsp;By&nbsp;
                    {!loginStatus ? event?.user.name : event?.user.address.toLowerCase() === account.toLowerCase() ? "Me" : 
                    ((event?.user?.name === 'No Name' || event?.user?.name === 'NoName')? (event?.user?.address.substring(2, 8) + "...").toUpperCase():(event?.user?.name))}
                </Link>
                <p>
                    {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(event?.timestamp && event?.timestamp * 1000)} 
                </p>

            </span>
        </div>
        <div className="price">
            <h6>{event?.price || '0'} MTV</h6>
            <p> = {(event?.price * parseFloat(rate)).toFixed(3) || '0'}USD</p>

        </div>
    </li>
  )
}
