import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { isAddress } from '../../utils/contracts';
import './import.scss'

export default function ImportCollection(props) {
    const { connector, library, account, active, chainId } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    let newVariable = process.env.REACT_APP_NETWORK_ID;
    useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId, newVariable]);

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [colAddr, setColAddr] = useState("");
    const [royalty, setRoyalty] = useState(0);
    const handleSubmit = (e) => {
        if (!loginStatus) {
            toast.error("Please connect to your wallet");
            return;
        }
        if (colAddr === ""){
            toast.error("Please input the collection address.");
            return;
        }
        if (!isAddress(colAddr)){
            toast.error("Collection Address Validation Error");
            return;
        }
        let load_toast_id = toast.loading("Please wait...");
        setLoading(true);
        axios
            .get(`/api/syncNFT`, { params: {
                account : account, 
                address : colAddr,
                royalty : royalty
            } })
            .then((res) => {
                console.log(res);
                setLoading(false);
                toast.dismiss(load_toast_id);
                toast.success(res.data.message);
                history.push("/collections");
            })
            .catch((err) => {
                setLoading(false);
                toast.dismiss(load_toast_id);
                toast.error(err.response.data.message);
            });
    }

    return (
        <section className="import author-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        {/* Item Form */}
                        <div id="contact-form" className="item-form card no-hover">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <input type="text" onChange={(e) => setColAddr(e.target.value)} className="form-control" name="address" placeholder="Collection Address to import" required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <input type="number" min={0} onChange={(e) => setRoyalty(parseFloat(e.target.value))} className="form-control" name="royalty" placeholder="Royalty(%)" required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    {!loading && <button onClick={handleSubmit} className="btn w-100 mt-3 mt-sm-4" type="submit">Import Collection</button>}
                                </div>
                            </div>
                        </div>
                        <p className="form-message" />
                    </div>
                </div>
            </div>
        </section>
    );
}


// 0x3f7b27aefedb98f3839c7b52745428ac6aa87ef4 - 3%
// 0x11860736901de2ea6e45e74bdf9d0737655a78e8 - 2%
// 0xef68a81dccf28efeec42043dcd6bbd22701af4d0 - 1%
// 0xfb95371e24df8c4928ae07ce8d5ec1f99164039a - 3%
// 0x046582b91d68cc5fdf02a045920c017140a8e503 - 5%
// 0x7113bd45e0428b02c97964a08f7505b5610797d5 - 5%
// 0xddffe501ea1c44e79bda4c52c8e1fd7c73f0a637 - 3%
// 0x70533ad4fad157351294ee906cc2f09c922d213a - 3%
// 0x612f1b4b7ff558df2af0af0dae14a0a69c0bdb0f - 1%
// 0xa4b775e2563fa3a7f4d82ada9595383edc4bcb68 - 4%
// 0x9f2c29e758f280bbc5f6d46cd534cda2e542a9cb - 5%