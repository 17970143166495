import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import { getIpfsHashFromFile } from "../../utils/ipfs";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './editProfile.scss'
export default function EditAuthor(props) {

    const { connector, library, account, active, chainId } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    let newVariable = process.env.REACT_APP_NETWORK_ID;
    useEffect(() => {
        const isLoggedin =
            account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId, newVariable]);

    let history = useHistory()
    const [isFirst, setFirst] = useState(true); //is used for displaying user data when enter in this screen.
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [bio, setBio] = useState("");
    const [email, setEmail] = useState("");
    const [site, setSite] = useState("");
    const [discord, setDiscord] = useState("");
    const [instagram, setInstagram] = useState("");
    const [twitter, setTwitter] = useState("");
    const [avatar_url, setAvatarUrl] = useState("");
    const [banner_url, setBannerUrl] = useState("");
    const [avatar, setAvatar] = useState(null);
    const [banner, setBanner] = useState(null);

    useEffect(() => {
        if (isFirst && loginStatus) {
            axios.get(`/api/user/${account}`).then((res) => {
                setProfile(res.data.user);
                setFirst(false);
            });
        }
    });

    function setProfile(_user) {
        if (_user) {
            setName(_user.name);
            setBio(_user.bio);
            setEmail(_user.email);
            setSite(_user.site_link);
            setDiscord(_user.social_discord_link);
            setTwitter(_user.social_twitter_link);
            setInstagram(_user.social_instagram_link);
            setBannerUrl(_user.banner_url);
            setAvatarUrl(_user.logo_url);
        }
    }

    const avatarHandle = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setAvatar(e.target.files[0]);
            setAvatarUrl("");
        }
    };
    const BannerImgChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setBanner(e.target.files[0]);
            setBannerUrl("");
        }
    };
    const nameHandle = (e) => {
        setName(e.target.value);
    };
    const bioHandle = (e) => {
        setBio(e.target.value);
    };
    const emailHandle = (e) => {
        setEmail(e.target.value);
    };
    const discordHandle = (e) => {
        setDiscord(e.target.value);
    };
    const instagramHandle = (e) => {
        setInstagram(e.target.value);
    };
    const twitterHandle = (e) => {
        setTwitter(e.target.value);
    };
    const removeLogo = () => {
        setAvatar(null);
    };
    const removeBanner = () => {
        setBanner(null);
    };

    async function onSave() {
        if (!loginStatus) {
            toast.error("Please connect to your wallet");
            return;
        }
        setIsLoading(true);
        let load_toast_id = toast.loading("Please wait...");
        let avatar_temp_url = avatar_url;
        if (avatar) {
            let avatar_hash = await getIpfsHashFromFile(avatar);
            avatar_temp_url = `https://mtv_color.mypinata.cloud/ipfs/${avatar_hash}`;
        }
        let banner_temp_url = banner_url;
        if (banner) {
            let banner_hash = await getIpfsHashFromFile(banner);
            banner_temp_url = `https://mtv_color.mypinata.cloud/ipfs/${banner_hash}`;
        }
        const data = {
            address: account,
            name: name || "NoName",
            bio: bio || "",
            email: email || "",
            site_link: site || "",
            social_twitter_link: twitter || "",
            social_instagram_link: instagram || "",
            social_discord_link: discord || "",
            logo_url: avatar_temp_url,
            banner_url: banner_temp_url,
            // logo_url: "https://mtv_color.mypinata.cloud/ipfs/QmdkB2xajtJA9GmuqEpTuwTzpMLVj7Ym4YhToyp43DDCnv",
            // banner_url: "https://mtv_color.mypinata.cloud/ipfs/QmW6bqSEvgr4tFScBdkpQ2SjUuu2QSJ7WVdxfkN2AHGkYf"
        };
        axios
            .post(`/api/user/update/`, data)
            .then((res) => {
                removeBanner();
                removeLogo();
                setProfile(res.data.user);
                setIsLoading(false);
                toast.dismiss(load_toast_id);
                toast.success("Profile is updated successfully.");
                if (history.action !== 'POP') history.goBack();
            })
            .catch((err) => {
                setIsLoading(false);
                toast.dismiss(load_toast_id);
                toast.error("Profile Updating is failed.");
            });
    }

    return (
        <>
            {/* Breadcrumb  */}
            <section
                style={{
                    background: banner ? `url(${URL.createObjectURL(banner)}) no-repeat scroll top center/cover ` :
                        (banner_url && banner_url !== "") ? `url(${banner_url}) no-repeat scroll top center/cover ` :
                            'url(/img/inner_bg.jpg) scroll top center / cover '
                }}
                className="breadcrumb-area overlay-dark d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Breadcrumb Content */}
                            <div className="breadcrumb-content text-center">
                                <h2 className="m-0">Edit Profile</h2>
                                <ol className="breadcrumb d-flex justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to={`/profile`}>Profile</Link></li>
                                    <li className="breadcrumb-item active">Edit Profile</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="editProfile author-area">
                <div className="container-div">
                    <div className="row justify-content-between">
                    <div className="col-12 col-md-3">
                            {/* Preview Window */}
                            <div className="card no-hover text-center">
                                <div className="image-over">
                                    {/* <img className="card-img-top" src={this.state.banner || this.state.data.img} alt="" /> */}
                                    {
                                        avatar && avatar_url === "" && <img className="rounded-circle" src={URL.createObjectURL(avatar)} alt="" />
                                    }
                                    {
                                        !avatar && avatar_url && <img className="rounded-circle" src={avatar_url} alt="" />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-9">
                            {/* Item Form */}
                            <div className="item-form card no-hover">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="area-upload">
                                            <label className='text-white pl-2 mb-3'>Logo</label>
                                            <div className="input-group form-group">
                                                <div className="custom-file">
                                                    <input type="file" required onChange={(e) => avatarHandle(e)} className="custom-file-input" id="inputGroupFile01" />
                                                    <label className="custom-file-label border-input" htmlFor="inputGroupFile01">Logo Image (350 x 350 recommended.)</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mt-3">
                                        <div className="area-upload">
                                            <label className='text-white pl-2 mb-3'>Banner</label>
                                            <div className="input-group form-group">
                                                <div className="custom-file">
                                                    <input type="file" required onChange={(e) => BannerImgChange(e)} className="custom-file-input" id="inputGroupFile01" />
                                                    <label className="custom-file-label border-input" htmlFor="inputGroupFile01"> Banner image (1400 x 400 recommended)</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-12 mt-3">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control border-input" onChange={(e) => nameHandle(e)} value={name} name="name" placeholder="Name" required />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea className="form-control border-input" onChange={(e) => bioHandle(e)} value={bio} name="bio" placeholder="Bio" cols={30} rows={3} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="email" className="form-control border-input" onChange={(e) => emailHandle(e)} value={email} name="email" placeholder="Email Address" required />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group border-input">
                                            <input type="text" className="form-control" onChange={(e) => discordHandle(e)} value={discord} name="discord" placeholder="Your Discord Link" />
                                            <input type="text" className="form-control" onChange={(e) => twitterHandle(e)} value={twitter} name="twitter" placeholder="Your Twitter Link" />
                                            <input type="text" className="form-control" onChange={(e) => instagramHandle(e)} value={instagram} name="instagram" placeholder="Your Instagram Link" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4" onClick={() => onSave()}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )

}
