import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import { listItem } from "../../utils/contracts";
import FixedPrice from "./FixedPrice";
import axios from "axios";
import { ArrowBack } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import './style.scss'
function ListItemPage(props) {

    const { item, onClose, rate } = props;

    const { connector, library, account, active, chainId } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    let newVariable = process.env.REACT_APP_NETWORK_ID;
    useEffect(() => {
        const isLoggedin =
            account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId, newVariable]);


    const [saleType, setSaleType] = useState("FixedPrice");
    const [isLoading, setIsLoading] = useState(false);
    const [price, setPrice] = useState('');

    const onList = async () => {
        if (!loginStatus) {
            toast.error("Please connect your wallet correctly!");
            return;
        }

        if (saleType === "FixedPrice") {
            if (!price) {
                toast.error("NFT Price is required!");
                return;
            }

            if (parseFloat(price) <= 0) {
                toast.error("NFT price should be more than 0!");
                return;
            }
        }
        setIsLoading(true);
        const load_toast_id = toast.loading("Please wait...");
        const tokenId = await listItem(
            item?.itemCollection,
            account,
            item?.tokenId,
            item.collection.royaltyAddr === "" ? item?.collection.owner : item?.collection.royaltyAddr,
            price,
            chainId,
            library.getSigner()
        )
        if (tokenId) {
            axios
                .get(`/api/syncCollection`, {params:{address:item?.collection.address}})
                .then((res) => {
                    toast.dismiss(load_toast_id);
                    toast.success("Listed Successfully");
                    setIsLoading(false);
                    onClose(true);
                })
                .catch((err) => {
                    console.log(err);
                    toast.dismiss(load_toast_id);
                    toast.error("Listing failed.");
                });
        } else {
            toast.dismiss(load_toast_id);
            toast.error("Listing failed.");
        }
    };
    const handleClose = () => {
        onClose(false);
    };

    return (
        <Modal
            className="myModal listingPage d-flex align-items-center justify-content-center"
            open={true}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="modal-content d-flex align-items-center col-lg-6 col-md-8 col-12 p-5 rounded justify-content-center ">
                <div className="w-100 mb-3">
                    <button onClick={handleClose} className="bakc text-white">
                        <ArrowBack />
                    </button>
                </div>
                <div className="saleContainer">
                    <div className="row">
                        <FixedPrice setPrice={setPrice} rate={rate} item={item}/>
                    </div>
                    <button className="listBtn btn-secondary mt-3 py-2 px-5" onClick={() => onList()}>
                        List
                    </button>
                </div>
            </div>
        </Modal>
    );
}
export default ListItemPage;