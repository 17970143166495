import { useState, useEffect } from 'react';
import Select from "react-select";
import FormatOptionLabel from "./item/FormatOptionLabel";
import UploadFile from "./uploadFile/UploadFile";
import axios from "axios";
import toast from "react-hot-toast";

import { useWeb3React } from "@web3-react/core";
import { getIpfsHash, getIpfsHashFromFile } from "../../utils/ipfs";
import { addItem } from "../../utils/contracts";
import './createItem.scss'
import NFTCard1 from 'components/card/nftCard1/NFTCard1';

interface MyDataType {
    assetUrl?: string;
    timestamp?: Date;
    name?: string;
    pair? : {
      price ?: number;
    };
    rate ? : number;
  }

const CreateItem = (props) => {
    const { connector, library, account, active, chainId } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    let newVariable = process.env.REACT_APP_NETWORK_ID;
    useEffect(() => {
        const isLoggedin =
            account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId, newVariable]);

    const [isLoading, setIsLoading] = useState(false);
    const [fileSrc, setFileSrc] = useState("");
    const [fileType, setFileType] = useState("");
    const [previewSrc, setPreviewSrc] = useState("");
    const [previewURL, setPreviewURL] = useState("");
    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState("");
    const [externalLink, setExternalLink] = useState("");
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [unlockable, setUnLockable] = useState(false);
    const [lockContent, setLockContent] = useState("")
    const [collections, setCollections] = useState([]);
    const [properties, setProperties] = useState([
        {
            trait_type: "",
            value: "",
        }
    ])

    const [createdData, setCreatedData]  = useState<MyDataType>()
  
    useEffect(() => {
        var data = {
            assetUrl : previewURL,
            timestamp: new Date(),
            name: name,
            pair : {
                price : price,
            },
            rate  : 1,
        }
        console.log(previewURL)
        setCreatedData(data);
  }, [setCreatedData, fileSrc, name, price]);

    useEffect(() => {
        if (collections.length === 0 && loginStatus) fetchCollections();
    }, [selectedCollection, loginStatus]);

    function fetchCollections() {
        axios
            .get(`/api/collection`, { params: { owner: account, date: 0, isImported:false } })
            .then((res) => {
                if (res.data.collections.length === 0) {
                    toast.error("You have no own collections, create it first.")
                    return;
                }
                setCollections(res.data.collections);
                res.data.collections.forEach((item, i) => {
                    if (item.isPublic) {
                        setSelectedCollection(item);
                        console.log(item);
                    }
                });
            })
            .catch((err) => {
                console.log("err: ", err.message);
                setCollections([]);
            });
    }

    async function onCreateItem() {
        if (!loginStatus) {
            toast.error("Please connect your wallet correctly");
            return;
        }

        if (collections.length === 0) {
            toast.error("You have no own collections, create it first.")
            return;
        }

        if (!fileSrc) {
            toast.error("NFT Art Image is required");
            return;
        }
        if ((fileType === "video" || fileType === "audio") && !previewSrc) {
            toast.error("NFT Preview Image is required");
            return;
        }
        if (!name) {
            toast.error("Name is required");
            return;
        }
        if (!description) {
            toast.error("Description is required.");
            return;
        }
        const load_toast_id = toast.loading("Please wait...");
        try {
            setIsLoading(true);
            let hash = await getIpfsHashFromFile(fileSrc);
            const assetUrl = `https://mtv_color.mypinata.cloud/ipfs/${hash}`;
            let previewUrl = "";
            if (fileType === "video" || fileType === "audio") {
                let previewHash = await getIpfsHashFromFile(previewSrc);
                previewUrl = `https://mtv_color.mypinata.cloud/ipfs/${previewHash}`;
            }
            let metaData = {
                assetType: fileType,
                image: fileType === "image" ? assetUrl : previewUrl,
                animation_url: fileType === "image" ? "" : assetUrl,
                name: name,
                description: description,
                external_url: externalLink,
                unlockable: unlockable,
                lockContent: lockContent,
                attributes: properties,
                itemCollection: selectedCollection.address,
            };
            const metaDataHash = await getIpfsHash(metaData);
            const tokenUri = `https://mtv_color.mypinata.cloud/ipfs/${metaDataHash}`;
            let isCreated = await addItem(
                selectedCollection.address,
                tokenUri,
                chainId,
                library.getSigner()
            );
            if (isCreated) {
                toast.success("NFT Product is created successfully");
                await axios.get(`/api/sync_block`);
                toast.dismiss(load_toast_id);
                props.history.push(`/explore`);
                setIsLoading(false);
            } else {
                toast.error("NFT Artist Create Failed!");
                toast.dismiss(load_toast_id);
                setIsLoading(false);
            }
        } catch (err) {
            console.log(err)
            toast.dismiss(load_toast_id);
            toast.error("NFT Artist Create Failed!");
        }
    }

    const handleRemoveProperties = (event, index) => {
        const newProperties = [...properties];
        newProperties.splice(index, 1);
        setProperties(newProperties);
    }

    const handleProperties = (event, index) => {
        const newProperties = [...properties];
        const newProperty = newProperties[index];
        newProperty[event.target.name] = event.target.value;
        setProperties(newProperties);
    }
    console.log(props.history.location.pathname)
    return (
        <section className="author-area createItem">
            <div className="container-dov">
                <div className="row justify-content-between">
                    {/* creat item form  */}
                    <div className="preview col-12 col-md-3">
                        <div className="intro mb-2">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">Preview Item</h3>
                            </div>
                        </div>
                        <NFTCard1 isPreview data = {createdData}/>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="intro mb-2">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">Create New {props.history.location.pathname.includes('collection')? "Collection":"Item"}</h3>
                            </div>
                        </div>
                        {/* Item Form */}
                        <div className="item-form card no-hover">
                            <div className="row">
                                    <div className="area-upload">
                                        <label className='text-white pl-2'>Upload File</label>
                                        <UploadFile setFileSrc={setFileSrc} setFileType={setFileType} setPreview={setPreviewSrc}setPreviewURL = {setPreviewURL} />
                                    </div>
                                <div className="col-12 mt-3">
                                    <div className="form-group mt-3">
                                        <label className='text-white pl-2 mb-2'>Price</label>
                                        <input type="number" className="form-control" onChange={(e) => setPrice(parseFloat(e.target.value))} value={price.toString()} name="name" placeholder="Enter price one item" required />
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="form-group mt-3">
                                        <label className='text-white pl-2 mb-2'>Title</label>
                                        <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} value={name} name="name" placeholder="Item Name" required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                    <label className='text-white pl-2 mb-2'>Description</label>
                                        <textarea className="form-control" onChange={(e) => setDescription(e.target.value)} value={description} name="description" placeholder="Description" cols={30} rows={3} required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group mt-3">
                                    <label className='text-white pl-2 mb-2'>External link</label>
                                        <input type="text" className="form-control" onChange={(e) => setExternalLink(e.target.value)} value={externalLink} name="external_link" placeholder="External link (your item details page)" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3 text-light">
                                        <label className='text-white'>Select Collection</label>
                                        <Select
                                            defaultValue={selectedCollection}
                                            formatOptionLabel={FormatOptionLabel}
                                            options={collections}
                                            instanceId="collectionSelect"
                                            onChange={(e) => setSelectedCollection(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    {/* <!-- Button trigger modal --> */}
                                    <div className="py-3 d-flex justify-content-between px-3 rounded text-left btn-dark w-100" data-toggle="modal" data-target="#exampleModal">
                                        <div className='d-flex'>
                                            <div> <i className='fa fa-list-ul m-3' /> </div>
                                            <div>
                                                <div><strong>Properties</strong></div>
                                                <div>Textual Traits that show up as rectangles</div>
                                            </div>
                                        </div>
                                        <div>  <i className='fa fa-plus-square m-3' /> </div>
                                    </div>


                                    {/* <!-- Modal --> */}
                                    <div className="modal fade" id="exampleModal" tabIndex ={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header mx-4 mt-3 p-0">
                                                    <button type="button" className="close  text-white" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <h5 className="modal-title text-center" id="exampleModalLabel">Add Properties</h5>
                                                <div className="modal-body">

                                                    <div className="card-div p-0 m-0">
                                                        <table className="table table-borderless">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    <th className='text-white' scope="col">Trait_Type</th>
                                                                    <th className='text-white' scope="col">Value</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    properties.map((property, index) => (
                                                                        <tr key={index}>
                                                                            <td> <button onClick={(e) => handleRemoveProperties(e, index)} className='closeBtn text-white'>X</button> </td>
                                                                            <td><input value={property.trait_type} name='trait_type' onChange={(e) => handleProperties(e, index)} className='form-control bg-dark border-0 text-white' type="text" placeholder='Character' /></td>
                                                                            <td> <input value={property.value} name='value' onChange={(e) => handleProperties(e, index)} type="text" className='form-control text-white bg-dark border-0' placeholder='Male' /> </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                <tr>
                                                                    <td></td>
                                                                    <td colSpan= {2}>
                                                                        <button onClick={() => setProperties([...properties, {
                                                                            trait_type: "",
                                                                            value: '',
                                                                        }])} className="addMore py-2 text-white col-lg-4 col-md-6 col-6 btn-bordered-white" >
                                                                            Add More
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                        <button data-dismiss="modal" className="w-100 btn mt-2" >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group mt-3">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkBox" onChange={() => setUnLockable(!unlockable)} name="inlineRadioOptions" id="inlineRadio2" checked={unlockable} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">Unlockable Content</label>

                                        </div>
                                        {
                                            unlockable && <div className="col-12">
                                                <div className="form-group">
                                                    <textarea className="form-control" onChange={(e) => setLockContent(e.target.value)} value={lockContent} name="textarea" placeholder="Write Down Secret Word Here About Your Item" cols={30} rows={3} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button onClick={() => onCreateItem()} className="btn w-100 mt-3 mt-sm-4">Create Item</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CreateItem;