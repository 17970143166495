
import { Button } from "@material-ui/core";
import { Modal } from "@mui/material";

type PropsType = {
  onClose ? :any
  onSubmit ? :any
  balance ? : number
}
export default function CancelListPage({ onClose, onSubmit, balance }:PropsType) {

  const handleClose = () => {
    onClose();
  };

  const onCancelListing = () => {
    handleClose();
    onSubmit();
  };
  return (
    <Modal
      className="myModal cancelListPage d-flex align-items-center justify-content-center"
      open={true}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-content rounded d-flex align-items-center p-5 col-lg-8 col-md-10 col-12">
        <div className="text-whitet title h4 text-whitet text-center">
          Are you sure you want to cancel your listing?
        </div>
        <div className="line c-w"></div>
        <div className="row-div my-2">
          <p className=" text-center">
            Canceling your listing will unpublish this sale from this site and
            requires a transaction to make sure it will never be fulfillable.
          </p>
        </div>
        <br />
        <div className="btns">
          <Button
            className="mx-2"
            onClick={handleClose}
            variant="contained"
            color="primary"
          >
            Never mind
          </Button>
          <Button
            className="mx-2"
            onClick={onCancelListing}
            variant="contained"
            color="primary"
          >
            Cancel Listing
          </Button>
        </div>
      </div>
    </Modal>
  );
}
