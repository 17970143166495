import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
import { CATEGORIES, createdBy, volumeBy, volumeSortBy } from '../../utils/options';
import './collections.scss'
import { useMediaQuery } from "react-responsive";
import NFTCard from 'components/card/nftCard/NFTCard';
import CollectionCard from 'components/card/collectionCard/CollectionCard';
import LeftFilter from './leftFilter/LeftFilter';
import Loader from 'components/loader/Loader';
import CollectionCard1 from 'components/card/collectionCard/CollectionCard1';

type PropsType = {
    filter?: number//filter 0 : All Collections, 1 : My Collections, 2 : Popular Collections
}
function Collections({
    filter
}: PropsType) {
    const [displayFilter, setDisplayFilter] = useState(-1);
    const [collections, setCollections] = useState([]);
    const [page, setPage] = useState(0);

    const { connector, library, account, active, chainId } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    let newVariable = process.env.REACT_APP_NETWORK_ID;
    const [itemCount, setItemCount] = useState(0);
    const [rate, setRate] = useState(0);
    useEffect(() => {
        const isLoggedin =
            account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId, newVariable]);

    let location = useLocation();
    const userAccount = filter === 1 ? location.pathname.split("/")[2] : "";

    const [isLoaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    function onReload() {
        setLoading(false);
        setLoaded(false);
        setPage(0);
        setCollections([]);
        setDisplayFilter(filter);
    }
    useEffect(() => {
        if (displayFilter !== filter) {
            onReload();
        }
    })

    useEffect(() => {
        if (!isLoaded) fetchCollections();
    }, [loginStatus, isLoaded, displayFilter, page])

    function fetchCollections() {
        let paramData = {
            page: page,
            category:undefined,
            sortBy: volumeOrder,
            byVolume: byVolume,
            searchTxt: searchTxt,
            owner: undefined,
        };
        
        if (category !== "all")paramData.category = [category, "sensitive"];
        if (userAccount !== "" && displayFilter === 1) {
            paramData.owner = userAccount;
        }else if (displayFilter === 2 || displayFilter === 0 ){
            
        }else return;

        axios.get(`/api/collection`, { params: paramData }).then((res) => {
            setLoading(false);
            setItemCount(res.data.count)
            setCollections(res.data.collections);
            setLoaded(true);
            setRate(parseFloat(res.data.rate));
            console.log(res.data.collections)
        }).catch((err) => {
            setLoading(false);
        });
    }

    const [searchTxt, setSearchTxt] = useState("");
    const [category, setCategory] = useState("all");
    const [byVolume, setByVolume] = useState(0);
    const [volumeOrder, setVolumeOrder] = useState(0);
    const handleVolumeOrderSelected = (e) => {
        setVolumeOrder(parseFloat(e.target.value));
        onReload();
    }

    const onChangePage = (_page) => {
        if (page === _page) return;
        setPage(_page);
        setLoading(false);
        setLoaded(false);
        setCollections([]);
        setDisplayFilter(filter);
    }

    const [index, setIndex] = useState(0);
    const handleClick = (value: string) => {
        if (value === 'prev') {
            if (index > 0) {
                setIndex(index - 1)
            } else {
                if(collections.length < 4){
                    setIndex(collections.length - 3)
                }else{
                    setIndex(4)
                }
            }
        }
        if (value === 'next') {
            if (index < 4 || collections.length < 4) {
                setIndex(index + 1)
            }
            else {
                setIndex(0)
            }
        }
        if (value === 'prevPage') {
            if (page > 0) {
                onChangePage(page - 1)
            } else {
                onChangePage(totalPage - 1)
            }
        }
        if (value === 'nextPage') {
            if (page < totalPage - 1) {
                onChangePage(page + 1)
            }
            else {
                onChangePage(0)
            }
        }
    }
    // Filter part
    const handleCategorySelected = (e) => {
        setCategory(e.target.value);
        onReload();
    }

    const handleVolumeSelected = (e) => {
        setByVolume(parseFloat(e));
        onReload();
    }

    useEffect(() => {
        if (!isLoaded) return;
        const _delay = setTimeout(() => {
            onReload();
        }, 2000)
        return () => clearTimeout(_delay)
    },[searchTxt])

    const imgsPerPage = 20
    const [indexArray, setIndexArray] = useState([0, 1, 2, 3, 4, 5, 6, 7])
    const [totalPage, setTotalPage] = useState(0)
    const [pageArray, setPageArray] = useState([])
    useEffect(() => {
        var total = itemCount % imgsPerPage === 0 ? Math.floor(itemCount / imgsPerPage) : Math.floor(itemCount / imgsPerPage) + 1
        if (total === 3) {
            setPageArray([0, 1, 2])
        }
        if (total === 4) {
            setPageArray([0, 1, 2, 3])
        }
        if (total === 5) {
            setPageArray([0, 1, 2, 3, 4])
        }
        if (total === 6) {
            setPageArray([0, 1, 2, 3, 4, 5])
        }
        if (total === 7) {
            setPageArray([0, 1, 2, 3, 4, 5, 6])

        }
        if (total > 7) {
            setPageArray(Array(total).fill(''))
        }
        setTotalPage(total)
        setIndexArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19])
    }, [setIndexArray, itemCount, setPageArray, imgsPerPage])
    return (
        <section style={{}} className="popular-collections-area">

            {displayFilter === 0 ?
                <div className="filterDiv">
                    <LeftFilter
                        setSearchTxt={setSearchTxt}
                        handleVolumeSelected={handleVolumeSelected}
                        searchTxt={searchTxt}
                    />
                </div> : null
            }
            {displayFilter === 0 ?
                <div className="container-div0">
                    <div className="row custom-selection mb-2 justify-content-start">
                        <div className="col-md-4 col-lg-2 col-6">
                            <select onChange={handleCategorySelected} className="custom-select text-white">
                                {
                                    CATEGORIES.map((category, idx) => {
                                        return <option value={category.value} key={idx}>
                                            {category.title}
                                        </option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="sort col-md-4 col-lg-3 col-6">
                            <select onChange={handleVolumeOrderSelected} className="custom-select text-white">
                                {
                                    volumeSortBy.map((volumeSort, idx) => {
                                        return <option key={idx} value={idx}>
                                            {volumeSort}
                                        </option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row collections">
                        {!isLoaded ?
                            <div className="loading-div">
                                <Loader />
                            </div> :
                            <div className="collectionList1">
                                {collections.length === 0? 
                                    <div className="loading-div">
                                        <h5 className='text-center' style={{width : '90vw'}}>No Collections</h5>
                                    </div>:
                                collections.map((collection, idx) => {
                                    return (
                                        idx % imgsPerPage === 0 ?
                                            indexArray.map((i) => {
                                                return (
                                                    i + idx < collections.length &&

                                                    <CollectionCard key={i + idx} byVolume={byVolume}  rate = {rate} data={collections[i + idx]} />
                                                )
                                            }) : null
                                    );
                                })}
                            </div>
                        }
                        <div className="pagenation1">
                            {totalPage > 1 &&
                                <>
                                    <div className="prevBtn" onClick={() => handleClick('prevPage')}><i className="fas fa-arrow-left"></i></div>
                                    <div className="page">

                                        {totalPage === 1 ?
                                            <span className={`${page === 0 ? 'activePage' : ''}`}
                                                onClick={() => onChangePage(0)}
                                            >{1}</span> :
                                            totalPage === 2 ?
                                                ([0, 1].map((idx) => (
                                                    <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                        onClick={() => onChangePage(idx)}
                                                    >{idx + 1}</span>

                                                ))) :
                                                totalPage === 3 ?
                                                    ([0, 1, 2].map((idx) => (
                                                        <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                            onClick={() => onChangePage(idx)}
                                                        >{idx + 1}</span>

                                                    ))) :
                                                    (totalPage > 3 && totalPage <= 7) ?
                                                        (pageArray.map((d, idx) => (
                                                            <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                                onClick={() => onChangePage(idx)}
                                                            >{idx + 1}</span>

                                                        ))) : ([0, 1, 2].map((idx) => (
                                                            <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                                onClick={() => onChangePage(idx)}
                                                            >{idx + 1}</span>

                                                        )))
                                        }

                                        {page > 3 &&
                                            <span>...</span>}

                                        {page >= 2 && page < totalPage - 3 &&
                                            <>
                                                {pageArray.map((d, idx) => (
                                                    idx > 2 &&
                                                    idx >= page - 1 &&
                                                    idx < page + 2 &&
                                                    idx < totalPage - 3 &&
                                                    <span className={`${idx === page ? 'activePage' : ''}`}
                                                        onClick={() => onChangePage(idx)}
                                                    >{idx + 1}</span>

                                                ))}

                                            </>
                                        }
                                        {page < totalPage - 4 && totalPage > 7 &&
                                            <span>...</span>}


                                        {totalPage > 7 &&
                                            <>
                                                <span className={`${page === totalPage - 3 ? 'activePage' : ''}`}
                                                    onClick={() => onChangePage(totalPage - 3)}
                                                >{totalPage - 2}</span>
                                                <span className={`${page === totalPage - 2 ? 'activePage' : ''}`}
                                                    onClick={() => onChangePage(totalPage - 2)}
                                                >{totalPage - 1}</span>
                                                <span className={`${page === totalPage - 1 ? 'activePage' : ''}`}
                                                    onClick={() => onChangePage(totalPage - 1)}
                                                >{totalPage}</span>
                                            </>
                                        }

                                    </div>
                                    <div className="nextBtn" onClick={() => handleClick('nextPage')}><i className="fas fa-arrow-right"></i></div>
                                </>
                            }

                        </div>
                    </div>
                </div> :
                <div className="container-div1">
                    {displayFilter === 2 &&
                        <div className="row">
                            <div className="col-12">
                                {/* Intro */}
                                <div className="intro d-flex justify-content-between align-items-end m-0">
                                    <div className="intro-content">
                                        <span>Most Popular Collections</span>

                                    </div>
                                </div>
                            </div>

                        </div>}
                    <div className="row collections">
                        {!isLoaded ?
                            <div className="loading-div">
                                <Loader />
                            </div> :
                            <div className="collectionList"
                                style={{ transform: `translateX(-${index * 22.5}vw)` }}
                            >
                                {collections.length === 0? 
                                    <div className="loading-div">
                                        <h5 className='text-center' style={{width : '90vw'}}>No Collections</h5>
                                    </div>:collections.map((collection, idx) => {
                                    return (
                                        idx < 8 &&
                                        <CollectionCard1 key={`c_${idx}`}  rate = {rate} data={collection} />
                                    );
                                })}
                            </div>
                        }
                        <div className="pagenation">
                            {collections.length - 3 > 1 &&
                                <>
                                    <div className="prevBtn" onClick={() => handleClick('prev')}><i className="fas fa-arrow-left"></i></div>
                                    {[0, 1, 2 , 3, 4].map((collection, idx) => (
                                        idx < collections.length - 3 &&
                                        <div  key ={idx} className={`dot ${index === idx ? 'activeDot' : ''} `} onClick={() => setIndex(idx)}></div>
                                    ))}
                                    <div className="nextBtn" onClick={() => handleClick('next')}><i className="fas fa-arrow-right"></i></div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </section>
    );

}

export default Collections;
