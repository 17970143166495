import { useState } from "react";

export default function FixedPrice(props) {
    const { setPrice, rate, item } = props;
    const [fixedPrice, setFixedPrice] = useState(0);
    const onChangePrice = (value) => {
        setFixedPrice(parseFloat(value.target.value));
        setPrice(value.target.value);
    };

    return (
        <div className="fixedprice">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text border-0 text-white" id="basic-addon1">Price</span>
                </div>
                <input
                    className="form-control text-white border-0"
                    name="price"
                    onChange={onChangePrice}
                    placeholder="Amount"
                    type="number"
                    step={.001}
                    min="0"
                />
            </div>
            <p className="usd">{(fixedPrice * rate).toFixed(2)} USD</p>
            <span className="detail w-100 d-flex align-items-center space-between">
                <p className="m-0"> Service Fee 2%</p>
                <p className="m-0"> Creator Fee {item?.royalty}% </p>
            </span>
            
        </div>
    );
}