import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <div className="">
                <footer className="bg-dark footer-area">
                    {/* Footer Bottom */}
                    <div className="container">
                        <div className="row ">
                            <div className=" col-lg-4 col-12 py-3 text-center">
                                <div><a className='text-white' target="_blank" href="https://epic-gallery.gitbook.io/epic/">Knowledge Base</a> </div>

                            </div>
                            <div className=" col-lg-4 col-12 ">
                                <div className="social-icons justify-content-center d-flex  ">
                                    <a className='twitter' target="_blank" href="https://twitter.com/EpicGalleryMTV">
                                        <i className='fab fa-twitter' />
                                        <i className='fab fa-twitter' />
                                    </a>
                                    <a className='discord' target="_blank" href='https://discord.gg/MxRneYPAUx'>
                                        <i className='fab fa-discord' />
                                        <i className='fab fa-discord' />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 py-3 text-center">
                                <div className="text-white">©2022 Epic Gallery, All Rights Reserved.</div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;