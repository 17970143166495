import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './sellerCard.scss'
type PropsType = {
    data ? :any
}
export default function SellerCard({data}:PropsType) {
    const [user, setUser] = useState<any>()
    useEffect(() => {
        setUser(data)
    }, [data, setUser]);

    const [byVolume, setByVolume] = useState(0);
    const handleVolumeSelected = (e) => {
        setByVolume(parseFloat(e.target.value));
    }
  return (
    <div className='sellerCard'>
        {/* Single Seller */}
        <div className="cardContent">
            <div className="single-seller d-flex align-items-center">
                <Link target="_blank" to={"/profile/" + user?.address}>
                    <img className="avatar-md rounded-circle" src={user?.logo_url} alt="" />
                </Link>
                {/* Seller Info */}
                <div className="seller-info ml-3">
                    <Link target="_blank" className="seller mb-2" to={"/profile/" + user?.address}>
                        {user?.name}
                    </Link>
                    <span>{user?.sumprice}MTV</span>
                </div>
            </div>
        </div>
    </div>
  )
}
