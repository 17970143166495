import './leftFilter.scss'
import Expand from "react-expand-animated";
import { useState } from 'react';
import { EVENT_TYPES } from 'utils/options';

type PropsType = {
    setTokenID?: any
    setCollectionAddress?: any,
    setFromAddress?: any,
    setToAddress?: any,
    setEventTypes?: any,
    tokenID?: number,
    collectionAddress?: string[]
    fromAddress?:string
    toAddress?:string
    eventTypes?: string[]
    collectionList?: any
}

export default function LeftFilter(
    {
        setTokenID,
        setCollectionAddress,
        setFromAddress,
        setToAddress,
        setEventTypes,
        tokenID,
        collectionAddress,
        fromAddress,
        toAddress,
        eventTypes,
        collectionList,
    }: PropsType) {
    const [isEventExpand, setIsEventExpand] = useState(false);
    const [isExpand1, setIsExpand1] = useState(false);
    const styles = {
        open: { width: "100%" },
        close: { width: "100%" }
    };
    const transitions = ["height", "opacity", "background"];

    const onClear = () => {
        setTokenID(-1);
        setCollectionAddress([]);
        setFromAddress("");
        setToAddress("");
        setEventTypes([]);
    }

    const handleChange = (e) => {
        const idx = collectionAddress.indexOf(e.target.value);
        if (idx > -1) collectionAddress.splice(idx, 1) //isExist
        else collectionAddress.push(e.target.value);
        setCollectionAddress([...collectionAddress]);
    }

    const handleEventChange = (e) => {
        const idx = eventTypes.indexOf(e.target.value);
        if (idx > -1) eventTypes.splice(idx, 1) //isExist
        else eventTypes.push(e.target.value);
        setEventTypes([...eventTypes]);
    }

    return (
        <div className='leftFilter'>
            <div className="filterHeader">
                <h6>Filter</h6>
                <button className='clear' onClick={onClear}>Clear All</button>
            </div>
            <div className="searchFilter">
                <div className="searchHeader">
                    <h5>Search by #TokenID</h5>
                </div>
                <div className="search">
                    <span>
                        <input
                            type="number"
                            id="tokenid"
                            name="tokenid"
                            value={tokenID >= 0 ? tokenID : ''}
                            onChange={(e) => !isNaN(parseFloat(e.target.value)) ? setTokenID(parseFloat(e.target.value)) : setTokenID(-1)}
                            placeholder="#TokenID"
                        />

                        <button><i className="fas fa-search"></i></button>
                    </span>

                </div>
            </div>
            
            <div className="searchFilter">
                <div className="searchHeader">
                    <h5>From Address</h5>
                </div>
                <div className="search">
                    <span>
                        <input
                            type="text"
                            id="fromAddress"
                            name="fromAddress"
                            value={fromAddress}
                            onChange={(e) => setFromAddress(e.target.value.toLowerCase())}
                            placeholder="From"
                        />
                    </span>

                </div>
            </div>
            
            <div className="searchFilter">
                <div className="searchHeader">
                    <h5>To Address</h5>
                </div>
                <div className="search">
                    <span>
                        <input
                            type="text"
                            id="toAddress"
                            name="toAddress"
                            value={toAddress}
                            onChange={(e) => setToAddress(e.target.value.toLowerCase())}
                            placeholder="To"
                        />
                    </span>

                </div>
            </div>
            

            <div className="filterHeader hover" onClick={() => setIsExpand1(!isExpand1)}>
                <h6>Collections List</h6>
                <span
                            style={{transition : 'all 0.3s ease', transform : `${isExpand1? "rotate(90deg)": "rotate(0deg)"}`}}
                            ><i className="fas fa-angle-right"></i></span>
            </div>
            <Expand
                open={isExpand1}
                duration={300}
                styles={styles}
                transitions={transitions}
            >
                <form action="" className='filterForm'>
                    {collectionList?.map((collection, key) => (
                        <span key={key}>
                            <input
                                type="checkbox"
                                id={`one_${key}`}
                                name={collection.address}
                                value={collection.address}
                                onChange={(e) => handleChange(e)}
                                checked={collectionAddress.indexOf(collection.address) > -1}
                            />
                            <label htmlFor={`one_${key}`}>{collection.name}</label>
                        </span>
                    ))}
                </form>

            </Expand>

            <div className="filterHeader hover" onClick={() => setIsEventExpand(!isEventExpand)}>
                <h6>Event Type</h6>
                <span
                            style={{transition : 'all 0.3s ease', transform : `${isEventExpand? "rotate(90deg)": "rotate(0deg)"}`}}
                            ><i className="fas fa-angle-right"></i></span>
            </div>
            <Expand
                open={isEventExpand}
                duration={300}
                styles={styles}
                transitions={transitions}
            >
                <form action='' className="eventFilterForm">
                    {
                        EVENT_TYPES?.map((_eventType, key) => (
                            <span key={`eventtype_${key}`}>
                                <input
                                    type="checkbox"
                                    id={`eventtype_${key}`}
                                    value={_eventType}
                                    name={_eventType}
                                    onChange={(e) => handleEventChange(e)} 
                                    checked={eventTypes.indexOf(_eventType) > -1}
                                />
                                <label htmlFor={`eventtype_${key}`}>{_eventType}</label>
                            </span>
                        ))
                    }
                </form>
            </Expand>
        </div>
    )
}
