import CollectionForm from 'components/Create/CreateCollection'
import { useLocation } from 'react-router-dom'
import Footer from '../components/Footer/Footer'

import ModalSearch from '../components/Modal/ModalSearch'
import Scrollup from '../components/Scrollup/Scrollup'

function CreateCollection(props) {
    const location = useLocation();
    const isCreate = location.pathname.split("/")[2] === "create";
    const collectionName = location.pathname.split("/")[3];
    return (
        <div className="main">
            <CollectionForm
                {...props}
                isCreate={isCreate}
                collectionName={collectionName} />
            <Footer />
            <ModalSearch />
            <Scrollup />
        </div>
    )
}

export default CreateCollection