import { useEffect, useState } from 'react';
import axios from 'axios';
import SellerCard from 'components/card/sellerCard/SellerCard';
import './topSeller.scss'
function TopSeller() {
 
    const [users, setUsers] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    useEffect(() => {
        if (!isLoaded)
        axios.get("/api/topsellers")
        .then((res) => {
            setLoaded(true);
            setUsers(res.data.users);
        })
        .catch((err) => {
            setLoaded(false);
            setUsers([]);
        });
    }, [users, isLoaded])

    // const isTabletOrMobile = useMediaQuery({ query: 'screen and (max-width: 768px) and (orientation:portrait)' })
    // const isLandOrMobile = useMediaQuery({ query: 'screen and (max-height: 768px) and (orientation:landscape)' })


    const [index, setIndex] = useState(0);
    const handleClick = (value:string)=>{
        if(value === 'prev'){
            if (index > 0){
                setIndex(index-1)
            }else{
                setIndex(users.length - 5)
            }
        }
        if(value === 'next'){
            if (index <= users.length - 6){
                setIndex(index + 1)
            }
            else{
                setIndex(0)
            }
        }
    }
    return (
        <section className="top-seller-area topSellerOne">
            <div className="container-div">
                <div className="row">
                    <div className="col-12">
                        {/* Intro */}
                        <div className="intro m-0">
                            <div className="intro-content">
                                <span>Creative Artist</span>
                                <div className="header">
                                    <h3 className="mt-3 mb-0">Top Sellers</h3>
                                    <div className="pagenation">
                                        <div className="prevBtn" onClick={()=>handleClick('prev')}>
                                            <i className="fas fa-angle-left"></i>
                                        </div>
                                        
                                        <div className="nextBtn" onClick={()=>handleClick('next')}>
                                            <i className="fas fa-angle-right"></i>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row items">
                    <div className="sellerList"
                    style={{transform : `translateX(-${index * 18}vw)`}}
                    >
                    {users.map((user, idx) => {
                        return (
                            <SellerCard key={`ts_${idx}`} data = {user}/>
                        );
                    })}
                    </div>
                    
                </div>
            </div>
        </section>
    );
}

export default TopSeller;