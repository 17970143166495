import './leftFilter.scss'
import Expand from "react-expand-animated";
import { useState } from 'react';
import { CATEGORIES } from 'utils/options';

type PropsType = {
    setSearchTxt?: any
    handleVolumeSelected?: any
    searchTxt?: string
}

export default function LeftFilter({ setSearchTxt, handleVolumeSelected, searchTxt }: PropsType) {
    const styles = {
        open: { width: "100%" },
        close: { width: "100%" }
    };
    const transitions = ["height", "opacity", "background"];
    const onChangeVol = (e) => {
        handleVolumeSelected(handleVolumeSelected)
    }

    const onClear = () => {
        setSearchTxt('')
    }
    const [isVolumeExpand, setIsVolumeExpand] = useState(true);
    return (
        <div className='leftFilter'>
            <div className="filterTop">
                <h3>Filter</h3>
                <div className="clear" onClick={onClear}>Clear All</div>
            </div>

            <div className="searchFilter">
                <div className="searchHeader">
                    <h5>Search by Name</h5>
                </div>
                <div className="search">
                    <span>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            onChange={(e) => setSearchTxt(e.target.value)}
                            placeholder="Enter Name"
                            value={searchTxt}
                        />

                        <button><i className="fas fa-search"></i></button>
                    </span>

                </div>
            </div>

            <div className="filterHeader hover" onClick={() => setIsVolumeExpand(true)}>
                <h6>Volume</h6>
                <span
                            style={{transition : 'all 0.3s ease', transform : `${isVolumeExpand? "rotate(90deg)": "rotate(0deg)"}`}}
                            ><i className="fas fa-angle-right"></i></span>
            </div>
            <Expand
                open={isVolumeExpand}
                duration={300}
                styles={styles}
                transitions={transitions}
            >

                <form action="" className='myform'>
                    <span>
                        <input type="radio" id="one" onChange={(e) => onChangeVol(e)} name="fav_language" value={0}
                            defaultChecked
                            className="radio" />
                        <label htmlFor="one">All</label>
                    </span>
                    <span>
                        <input type="radio" id="two" onChange={(e) => onChangeVol(e)} name="fav_language" value={1} className="radio" />
                        <label htmlFor="two">By 24h Volume</label>
                    </span>
                    <span>
                        <input type="radio" id="three" onChange={(e) => onChangeVol(e)} name="fav_language" value={2}
                            className="radio" />
                        <label htmlFor="three">By 72h Volume</label>
                    </span>
                </form>

            </Expand>
        </div>
    )
}
