import { useState } from "react";
import { isAddress } from "../../utils/contracts";
import toast from "react-hot-toast";
import './style.scss'
import { Modal } from "@mui/material";

function TransferPage(props) {

    const { onClose } = props;

    const [toAddress, setToAddress] = useState("");

    const onTransfer = (e) => {
        if (!isAddress(toAddress)) {
            toast.error("Address Validation Error");
            return;
        }
        onClose(toAddress);
    };

    return (
        <Modal
            className="myModal cancelListPage d-flex align-items-center justify-content-center"
            open={true}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div id="contact-form" className="modal-content item-form card no-hover">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group mt-3">
                            <input
                                    className="transferInput form-control bg-dark text-white border-0"
                                    name="address"
                                    onChange={(e) => setToAddress(e.target.value)}
                                    placeholder="0x000000000000000000000000000000000000000000000"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <button onClick={onTransfer} className="btn w-100 d-block btn btn-bordered-white mt-4" type="submit">Transfer</button>
                        </div>
                    </div>
            </div>
        </Modal>
    );
}
export default TransferPage;