import { ImportExport } from '@material-ui/icons';
import { compose } from '@mui/system';
import { CSVLink, CSVDownload } from "react-csv";
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import NFTCard from 'components/card/nftCard/NFTCard';
import NFTCard1 from 'components/card/nftCard1/NFTCard1';
import Loader from 'components/loader/Loader';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation } from 'react-router-dom';
import { SALE, sortBy, sortCollectionDetailBy } from 'utils/options';
import './explorer.scss'
import LeftFilter from './leftFilter/LeftFilter';

type PropsType = {
    showMethod?: number//showMethod : 0- All Items, 1- Collection Items, 2- My Items, 3- Sub Items
}
function Explorer({ showMethod }: PropsType) {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [displayMethod, setDisplayMethod] = useState(-1);

    const { connector, library, account, active, chainId } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    let newVariable = process.env.REACT_APP_NETWORK_ID;
    useEffect(() => {
        const isLoggedin =
            account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId, newVariable]);

    let location = useLocation();
    const userAccount = displayMethod === 2 ? location.pathname.split("/")[2] : "";
    const urlData = location.pathname.toString();

    const len = urlData.split("/").length;
    const collectionName = urlData.split("/")[len - 1];

    const [collection, setCollection] = useState(null);
    const [collectionList, setCollectionList] = useState(null);
    const [items, setItems] = useState([]);
    const [traitList, setTraitList] = useState([]);
    const [rarityList, setRarityList] = useState([]);

    const [itemCount, setItemCount] = useState(0);
    const [floorPrice, setFloorPrice] = useState(0);
    const [rate, setRate] = useState(0);
    useEffect(() => {
        if (displayMethod !== showMethod) {
            setLoading(false);
            setItems([]);
            setPage(0);
            setIsLoaded(false);
            setDisplayMethod(showMethod);
        }
    });
    const [isLoaded, setIsLoaded] = useState(false);
    const [csvData, setCSVData] = useState([]);
    const [isCSVLoading, setIsCSVLoading] = useState(false);

    useEffect(() => {
        if (!isLoaded) {
            fetchItems();
        }
    }, [isLoaded, page, loginStatus, displayMethod])

    function fetchItems() {
        let paramData = {
            page: page,
            saleType: saleType,
            sortBy: priceSort,
            tokenId: tokenID >= 0 ? tokenID : undefined,
            fromPrice: minPrice,
            toPrice: maxPrice,
            addresses: collectionAddress,
            rarityList: undefined,
            itemOwner: undefined,
            traits: undefined
        };
        if (displayMethod === 1) {
            setLoading(true);
            paramData.itemOwner = isMyNFT && loginStatus ? account.toLowerCase() : undefined;
            paramData.traits = isEmpty(selectedTraitList) ? undefined : JSON.stringify(selectedTraitList);
            paramData.rarityList = isEmpty(rarityList) ? undefined : JSON.stringify(rarityList);
            axios
                .get(`/api/collection/detail/${collectionName}`, { params: paramData })
                .then((res) => {
                    setLoading(false);
                    setCollection(res.data.collection);
                    setRate(parseFloat(res.data.rate));
                    setFloorPrice(res.data.floorPrice);
                    setItemCount(res.data.count)
                    setItems(res.data.items)
                    setIsLoaded(true);
                    setTraitList(res.data.collection.traits)
                    let _trait_list = selectedTraitList;
                    for (var i = selectedTraitList.length; i < res.data.collection.traits.length; i++) {
                        _trait_list.push(new Array<string>());
                    }
                    setSelectedTraitList([..._trait_list])
                })
                .catch((err) => {
                    setLoading(false);
                    setCollection(null);
                    setRate(0);
                });

        } else {
            if (displayMethod === 0 || displayMethod === 3) {
                paramData.itemOwner = isMyNFT && loginStatus ? account.toLowerCase() : undefined;
            } else if (displayMethod === 2) {
                paramData.itemOwner = userAccount.toLowerCase();
            } else return;

            setLoading(true);
            axios.get(`/api/item`, { params: paramData }).then((res) => {
                setLoading(false);
                setRate(parseFloat(res.data.rate));
                setCollectionList(res.data.collections)
                setItemCount(res.data.count)
                setItems(res.data.items)
                setIsLoaded(true);
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            })

            if (displayMethod === 2 && loginStatus && userAccount.toLowerCase() === account.toLowerCase() && !isCSVLoading) {
                setIsCSVLoading(true);
                axios.get(`/api/getCSVData/`, { params: paramData }).then((res) => {
                    const _csvData = [];
                    for (const item of res.data.items) {
                        let csvMetaData = [];
                        let collectionName = "";
                        for (const collection of res.data.collections) {
                            if (collection.address.toLowerCase() === item?.itemCollection) collectionName = collection.name;
                        }
                        csvMetaData.push(collectionName);
                        csvMetaData.push(item?.tokenId);
                        for (const property of item?.properties) {
                            csvMetaData.push(property.value);
                        }
                        _csvData.push(csvMetaData);
                    }
                    setCSVData([..._csvData]);
                    setIsCSVLoading(false);
                }).catch((err) => {
                    console.log(err);
                    setIsCSVLoading(false);
                })
            }
        }
    }

    const [isApproved, setApproved] = useState(false);
    const [isApproveLoading, setApproveLoading] = useState(false);
    const onApprove = (_isApproved) => {
        setApproveLoading(true);
        let metaData = {
            address: collection?.address,
            isApproved: _isApproved
        };
        axios
            .post(`/api/collection/approve`, metaData)
            .then((res) => {
                setApproved(_isApproved);
                setApproveLoading(false);
                toast.success(res.data);
            })
            .catch((err) => {
                setApproveLoading(false);
                toast.success("Failed");
                console.log(err);
            });
    };

    // Filter part
    const [maxPrice, setMaxPrice] = useState(0)
    const [minPrice, setMinPrice] = useState(0)
    const [tokenID, setTokenID] = useState(-1);
    const [isMyNFT, setIsMyNFT] = useState(false);
    const [saleType, setSaleType] = useState(showMethod === 2 ? 2 : 0)
    const [collectionAddress, setCollectionAddress] = useState<string[]>([]);
    const [selectedTraitList, setSelectedTraitList] = useState<string[][]>([]);

    function isEmpty(array) {
        return Array.isArray(array) && (array.length == 0 || array.every(isEmpty));
    }

    function isArrayEqual(array1, array2) {
        if (!Array.isArray(array1) && !Array.isArray(array2)) {
            return array1 === array2;
        }

        if (array1.length !== array2.length) {
            return false;
        }

        for (var i = 0, len = array1.length; i < len; i++) {
            if (!isArrayEqual(array1[i], array2[i])) {
                return false;
            }
        }

        return true;
    }

    useEffect(() => {
        console.log(account);
        if (!isLoaded) return;
        if (maxPrice !== 0 && maxPrice < minPrice) return;
        const _delay = setTimeout(() => {
            setLoading(false);
            setItems([]);
            setPage(0);
            setIsLoaded(false);
            setDisplayMethod(showMethod);
        }, 2000)
        return () => clearTimeout(_delay)
    }, [tokenID, isMyNFT, saleType, minPrice, maxPrice, collectionAddress, userAccount, rarityList])

    const copyHandle = () => {
        if (!loginStatus) {
            toast.error("Please connect your wallet.");
            return;
        }
        let textarea = document.createElement("textarea");
        textarea.textContent = collection?.address;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter");
        } finally {
            toast.success("Copied to Clipboard");
            document.body.removeChild(textarea);
        }
    };
    const [priceSort, setPriceSort] = useState(showMethod === 3 ? 2 : 0)
    const handlePriceSelected = (e) => {
        setPriceSort(parseFloat(e.target.value));
        setLoading(false);
        setItems([]);
        setPage(0);
        setIsLoaded(false);
        setDisplayMethod(showMethod);
    }

    const handleTrait = (_selectedTraitList) => {
        if (isArrayEqual(_selectedTraitList, selectedTraitList)) {
            return;
        }
        setSelectedTraitList([..._selectedTraitList]);
        const _delay = setTimeout(() => {
            setLoading(false);
            setItems([]);
            setPage(0);
            setIsLoaded(false);
            setDisplayMethod(showMethod);
        }, 1000)
        return () => clearTimeout(_delay)
    }

    const onChangePage = (_page) => {
        if (page === _page) return;
        setLoading(false);
        setItems([]);
        setPage(_page);
        setIsLoaded(false);
        setDisplayMethod(showMethod);
    }

    const [index, setIndex] = useState(0);
    const handleClick = (value: string) => {
        if (value === 'prev') {
            if (index > 0) {
                setIndex(index - 1)
            } else {
                if (items.length < 5) {
                    setIndex(items.length - 5)
                } else {
                    setIndex(5)
                }

            }
        }
        if (value === 'next') {
            if (index < 5 && index < items.length - 5) {
                setIndex(index + 1)
            }
            else {
                setIndex(0)
            }
        }
        if (value === 'prevPage') {
            if (page > 0) {
                onChangePage(page - 1)
            } else {
                onChangePage(totalPage - 1)
            }
        }
        if (value === 'nextPage') {
            if (page < totalPage - 1) {
                onChangePage(page + 1)
            }
            else {
                onChangePage(0)
            }
        }
    }
    const imgsPerPage = 20;
    const [indexArray, setIndexArray] = useState([0, 1, 2, 3, 4, 5, 6, 7])
    const [totalPage, setTotalPage] = useState(0)
    const [pageArray, setPageArray] = useState([])

    useEffect(() => {

        const total = itemCount % imgsPerPage === 0 ? Math.floor(itemCount / imgsPerPage) : Math.floor(itemCount / imgsPerPage) + 1
        if (total === 3) {
            setPageArray([0, 1, 2])
        }
        if (total === 4) {
            setPageArray([0, 1, 2, 3])
        }
        if (total === 5) {
            setPageArray([0, 1, 2, 3, 4])
        }
        if (total === 6) {
            setPageArray([0, 1, 2, 3, 4, 5])
        }
        if (total === 7) {
            setPageArray([0, 1, 2, 3, 4, 5, 6])

        }
        if (total > 7) {
            setPageArray(Array(total).fill(''))
        }

        setTotalPage(total)
        setIndexArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19])
    }, [setIndexArray, itemCount, setPageArray])

    return (
        <>
            {/* Breadcrumb  */}
            {(displayMethod === 0 || displayMethod === 1) &&
                <section
                    style={{
                        background: (displayMethod === 1 && collection?.banner_uri && collection?.banner_uri !== "") ? `url(${collection?.banner_uri}) no-repeat scroll top center/cover ` :
                            'url(/img/inner_bg.jpg) scroll top center / cover '
                    }}
                    className={`explorer breadcrumb-area ${displayMethod === 0 ? 'overlay-dark' : 'overlay-dark'} d-flex align-items-center`}>
                    <div className="container-div0">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content text-center">
                                    <h2 className="m-0">
                                        {displayMethod === 0 ?
                                            "Explorer" : ""
                                        }
                                    </h2>
                                    <p>{displayMethod !== 1 ?
                                        "Digital marketplace on MultiVac for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items."
                                        : ''
                                    }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
            {displayMethod === 1 &&
                <section className="explorer explore-area detail-top collection-area breadcrumb-area d-flex align-items-center">
                    {displayMethod === 1 && <img className='collection-logo' src={collection?.logo_uri} alt="" />}
                    {
                        loginStatus && displayMethod === 1 && ["0x23cf769423c5455649d0a0be69f6bed7b38e1e73","0xe79d8ac9bbbc82b6ce8d45f073855608b6b7c881"].includes(account.toLowerCase()) &&
                        <div className="collection-approve" onClick={() => onApprove(!isApproved)}>{isApproveLoading ? "Loading..." : isApproved ? "Unapprove" : "Approve"}</div>
                    }
                    {
                        loginStatus && displayMethod === 1 && collection?.owner.toLowerCase() === account.toLowerCase() &&
                        <div className="intro-btn collection-edit">
                            <a className="btn content-btn text-left" href={"/collection/edit/" + collectionName}>Edit</a>
                        </div>
                    }
                    <div className="container-div0">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content text-center">
                                    <h2 className="m-0">
                                        {collectionName}
                                    </h2>
                                    <p>{collection?.description}</p>
                                    <div className="input-group text-center row justify-content-center">
                                        <input type="text" value={collection?.address || ""} readOnly={true} className="form-control bg-dark border-0 col-lg-4" style={{ alignSelf: 'center', borderRadius: '5px' }} />
                                        <div className="input-group-append">
                                            <button className='bg-transparent border-0 text-white' style={{ cursor: "pointer" }} onClick={copyHandle}><i className="icon-docs " /></button>
                                        </div>

                                    </div>
                                    <div className="status justify-content-center d-flex">
                                        <ul>
                                            <li>
                                                <h5>{collection?.itemCount || 0}</h5>
                                                <p>Items</p>
                                            </li>
                                            <li>
                                                <h5>{collection?.ownerCount || 0}</h5>
                                                <p>Owners</p>
                                            </li>
                                            <li>
                                                <h5><img src="/img/icons/color_icon.png" alt="" /> {floorPrice}</h5>
                                                <p>Floor price</p>
                                            </li>
                                            <li>
                                                <h5><img src="/img/icons/color_icon.png" alt="" />{collection?.volume}</h5>
                                                <p>Volume</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="social-icons justify-content-center d-flex">
                                        {
                                            collection?.social_instagram_link && <a className='website' target="_blank" href={collection?.social_instagram_link}>
                                                <i className='fab fa-chrome' />
                                                <i className='fab fa-chrome' />
                                            </a>
                                        }
                                        {
                                            collection?.social_discord_link && <a className='discord' target="_blank" href={collection?.social_discord_link}>
                                                <i className='fab fa-discord' />
                                                <i className='fab fa-discord' />
                                            </a>
                                        }
                                        {
                                            collection?.social_telegram_link && <a className='twitter' target="_blank" href={collection?.social_telegram_link}>
                                                <i className='fab fa-twitter' />
                                                <i className='fab fa-twitter' />
                                            </a>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
            <section className="explorer explore-area collection-area">
                {displayMethod !== 3 ?
                    <div className="filterDiv">
                        <LeftFilter
                            setTokenID={setTokenID}
                            setIsMyNFT={setIsMyNFT}
                            setSaleType={setSaleType}
                            setMinPrice={setMinPrice}
                            setMaxPrice={setMaxPrice}
                            setCollectionAddress={setCollectionAddress}
                            setSelectedRarityList={setRarityList}
                            handleTrait={handleTrait}
                            selectedTraitList={selectedTraitList}
                            tokenID={tokenID}
                            isMyNFT={isMyNFT}
                            saleType={saleType}
                            collectionList={collectionList}
                            collectionAddress={collectionAddress}
                            minPrice={minPrice}
                            maxPrice={maxPrice}
                            displayMethod={displayMethod}
                            traitList={traitList}
                            selectedRarityList={rarityList}
                            colAddr={collection?.address}
                            loginStatus={loginStatus}
                        />
                    </div> : null}


                {displayMethod === 3 ?
                    <div className="container-div1" >
                        <div className="row">
                            <div className="col-12">
                                <div className="intro d-flex justify-content-between align-items-end m-0">
                                    {
                                        displayMethod === 3 ? <div className="intro-content">
                                            <span>New Listings</span>
                                            <h3 className="mt-3 mb-0"></h3>
                                        </div> :
                                            <div className="intro-content" />
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="row items explore-items nftShow1">
                            {loading ?
                                <div className="loading-div">
                                    <Loader />
                                </div> :
                                <div className="nftList"
                                    style={{ transform: `translateX(-${index * 18}vw)` }}
                                >
                                    {items.length === 0 ?
                                        <div className="col-12 mt-5 mb-5 d-flex justify-content-center align-items-center">
                                            <h5>No Items</h5>
                                        </div> :
                                        items.map((item, idx) => {
                                            return (
                                                idx < 10 &&
                                                <NFTCard1 key={idx} data={item} rate={rate} />
                                            );
                                        })}
                                </div>
                            }
                        </div>
                        <div className="pagenation">
                            {items.length - 4 > 1 &&
                                <>
                                    <div className="prevBtn" onClick={() => handleClick('prev')}>
                                        <i className="fas fa-arrow-left"></i>
                                    </div>
                                    {[0, 1, 2, 3, 4, 5].map((item, idx) => (
                                        (idx < items.length - 4) &&
                                        <div key={idx} className={`dot ${index === idx ? 'activeDot' : ''} `} onClick={() => setIndex(idx)}></div>
                                    ))}

                                    <div className="nextBtn" onClick={() => handleClick('next')}>
                                        <i className="fas fa-arrow-right"></i>
                                    </div>
                                </>}
                        </div>
                    </div> :
                    <div className="container-div0" >
                        <div className="row custom-selection justify-content-start">
                            <div className="col-md-4 col-lg-3 my-1 col-6">
                                <select onChange={handlePriceSelected} className="custom-select text-white">
                                    {
                                        displayMethod !== 1 ? sortBy.map((sortItem, idx) => {
                                            return <option key={idx} value={idx}>
                                                {sortItem}
                                            </option>
                                        }) : sortCollectionDetailBy.map((sortItem, idx) => {
                                            return <option key={idx} value={idx}>
                                                {sortItem}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>
                            {
                                displayMethod === 2 && loginStatus && userAccount.toLowerCase() === account.toLowerCase() && <div className="col-md-4 col-lg-3 my-1 col-6">
                                    <CSVLink
                                        filename={"My Wallet.csv"}
                                        data={csvData}>
                                        <button disabled={isCSVLoading} className='csvBtn'>{isCSVLoading ? "Loading CSV..." : "Download as CSV"}</button>
                                    </CSVLink>;
                                </div>
                            }

                        </div>
                        <div className="row items explore-items nftShow0">
                            <div className="nftList1">
                                {loading ?
                                    <Loader /> :
                                    (items.length === 0 ?
                                        <div className="col-12 mt-5 mb-5 d-flex justify-content-center align-items-center">
                                            <h5>No Items</h5>
                                        </div>
                                        :

                                        items.map((item, idx) => {
                                            return (
                                                idx % imgsPerPage === 0 ?
                                                    indexArray.map((i) => {
                                                        return (
                                                            i + idx < items.length &&

                                                            <NFTCard key={i + idx} data={items[i + idx]} rate={rate} />
                                                        )
                                                    }) : null

                                            );
                                        }))
                                }

                            </div>
                        </div>
                        <div className="pagenation1">
                            {totalPage > 1 &&
                                <>
                                    <div className="prevBtn" onClick={() => handleClick('prevPage')}><i className="fas fa-arrow-left"></i></div>
                                    <div className="page">
                                        {totalPage === 1 ?
                                            <span className={`${page === 0 ? 'activePage' : ''}`}
                                                onClick={() => onChangePage(0)}
                                            >{1}</span> :
                                            totalPage === 2 ?
                                                ([0, 1].map((idx) => (
                                                    <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                        onClick={() => onChangePage(idx)}
                                                    >{idx + 1}</span>

                                                ))) :
                                                totalPage === 3 ?
                                                    ([0, 1, 2].map((idx) => (
                                                        <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                            onClick={() => onChangePage(idx)}
                                                        >{idx + 1}</span>

                                                    ))) :
                                                    (totalPage > 3 && totalPage <= 7) ?
                                                        (pageArray.map((d, idx) => (
                                                            <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                                onClick={() => onChangePage(idx)}
                                                            >{idx + 1}</span>
                                                        ))) : ([0, 1, 2].map((idx) => (
                                                            <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                                onClick={() => onChangePage(idx)}
                                                            >{idx + 1}</span>
                                                        )))
                                        }

                                        {page > 3 &&
                                            <span>...</span>}

                                        {page >= 2 && page < totalPage - 3 &&
                                            <>
                                                {pageArray.map((d, idx) => (
                                                    idx > 2 &&
                                                    idx >= page - 1 &&
                                                    idx < page + 2 &&
                                                    idx < totalPage - 3 &&
                                                    <span className={`${idx === page ? 'activePage' : ''}`}
                                                        onClick={() => onChangePage(idx)}
                                                    >{idx + 1}</span>

                                                ))}

                                            </>
                                        }
                                        {page < totalPage - 4 && totalPage > 7 &&
                                            <span>...</span>}


                                        {totalPage > 7 &&
                                            <>
                                                <span className={`${page === totalPage - 3 ? 'activePage' : ''}`}
                                                    onClick={() => onChangePage(totalPage - 3)}
                                                >{totalPage - 2}</span>
                                                <span className={`${page === totalPage - 2 ? 'activePage' : ''}`}
                                                    onClick={() => onChangePage(totalPage - 2)}
                                                >{totalPage - 1}</span>
                                                <span className={`${page === totalPage - 1 ? 'activePage' : ''}`}
                                                    onClick={() => onChangePage(totalPage - 1)}
                                                >{totalPage}</span>
                                            </>
                                        }

                                    </div>
                                    <div className="nextBtn" onClick={() => handleClick('nextPage')}><i className="fas fa-arrow-right"></i></div>
                                </>
                            }
                        </div>
                    </div>
                }
            </section>
        </>

    );

}

export default Explorer;