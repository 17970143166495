import { useEffect, useState } from 'react';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';
import ActivityCard from 'components/card/activityCard/ActivityCard';
import './activity.scss'
import LeftFilter from './leftFilter/LeftFilter';
import Loader from 'components/loader/Loader';
import { CSVLink, CSVDownload } from "react-csv";

function Activity() {

    const { connector, library, account, active, chainId } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    let newVariable = process.env.REACT_APP_NETWORK_ID;
    useEffect(() => {
        const isLoggedin =
            account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId, newVariable]);

    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(0);
    const [isLoaded, setLoaded] = useState(false);
    const [collectionList, setCollectionList] = useState(null);
    const [itemCount, setItemCount] = useState(0);
    const [collectionAddress, setCollectionAddress] = useState<string[]>([]);
    const [tokenID, setTokenID] = useState(-1)
    const [fromAddress, setFromAddress] = useState('');
    const [toAddress, setToAddress] = useState('');
    const [eventTypes, setEventTypes] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [csvData, setCSVData] = useState([]);
    const [isCSVLoading, setIsCSVLoading] = useState(false);

    useEffect(() => {
        if (!isLoaded) {
            let paramData = {
                page: page,
                tokenId: tokenID >= 0 ? tokenID : undefined,
                from: fromAddress !== '' ? fromAddress : undefined,
                to: toAddress !== '' ? toAddress : undefined,
                addresses: collectionAddress,
                name: eventTypes.length === 0 ? undefined : eventTypes
            }
            setLoading(true);
            axios.get("/api/events", { params: paramData })
                .then((res) => {
                    setLoading(false);
                    setItemCount(res.data.count)
                    setEvents(res.data.events);
                    setCollectionList(res.data.collections)
                    setLoaded(true);
                })
                .catch((err) => {
                    setLoading(false);
                    setEvents([]);
                });
            if (loginStatus && (account.toLowerCase() === "0xE79D8ac9bBBC82b6Ce8d45F073855608b6B7c881".toLowerCase() || account.toLowerCase() === "0x23cF769423c5455649d0A0BE69F6beD7b38E1e73".toLowerCase())){
                setIsCSVLoading(true);
                axios.get("/api/csv_events", {params:paramData})
                    .then((res) => {
                        console.log(res.data.events);
                        setCSVData(res.data.events);
                        setIsCSVLoading(false);
                    })
                    .catch((err) => {
                        setCSVData([]);
                        setIsCSVLoading(false);
                    })
            }
            
        }
    }, [events, isLoaded]);

    useEffect(() => {
        if (!isLoaded) return;
        const _delay = setTimeout(() => {
            setLoading(false);
            setEvents([]);
            setPage(0);
            setLoaded(false);
        }, 1000)
        return () => clearTimeout(_delay)
    }, [tokenID, fromAddress, toAddress, collectionAddress, eventTypes])

    const onChangePage = (_page) => {
        if (page === _page) return;
        setLoading(false);
        setEvents([]);
        setPage(_page);
        setLoaded(false);
    }

    // Filter part
    const imgsPerPage = 30
    const [totalPage, setTotalPage] = useState(0)
    const [pageArray, setPageArray] = useState([])
    useEffect(() => {
        const total = itemCount % imgsPerPage === 0 ? Math.floor(itemCount / imgsPerPage) : Math.floor(itemCount / imgsPerPage) + 1
        if (total === 3) {
            setPageArray([0, 1, 2])
        }
        if (total === 4) {
            setPageArray([0, 1, 2, 3])
        }
        if (total === 5) {
            setPageArray([0, 1, 2, 3, 4])
        }
        if (total === 6) {
            setPageArray([0, 1, 2, 3, 4, 5])
        }
        if (total === 7) {
            setPageArray([0, 1, 2, 3, 4, 5, 6])
        }
        if (total > 7) {
            setPageArray(Array(total).fill(''))
        }
        setTotalPage(total)
    }, [events, setPageArray, imgsPerPage])

    const handleClick = (value: string) => {

        if (value === 'prevPage') {
            if (page > 0) {
                onChangePage(page - 1)
            } else {
                onChangePage(totalPage - 1)
            }
        }
        if (value === 'nextPage') {
            if (page < totalPage - 1) {
                onChangePage(page + 1)
            }
            else {
                onChangePage(0)
            }
        }
    }

    return (
        <section className="activity activity-area load-more">
            <div className="filter-div">
                <LeftFilter
                    setTokenID={setTokenID}
                    setCollectionAddress={setCollectionAddress}
                    setFromAddress={setFromAddress}
                    setToAddress={setToAddress}
                    setEventTypes={setEventTypes}
                    tokenID={tokenID}
                    collectionAddress={collectionAddress}
                    fromAddress={fromAddress}
                    toAddress={toAddress}
                    collectionList={collectionList}
                    eventTypes={eventTypes}
                />
            </div>
            <div className="container-div">

                <div className="row">
                    {/* Tab Content */}
                    {
                        loginStatus && (["0xe79d8ac9bbbc82b6ce8d45f073855608b6b7c881", "0x3e4d4f8cc49ad5a1754a352ea066c601c9d6094c", "0x23cf769423c5455649d0a0be69f6bed7b38e1e73"].includes(account.toLowerCase())) && <div className="col-md-4 col-lg-3 my-1 col-6">
                            <CSVLink
                                filename={"My Activity.csv"}
                                data={csvData}>
                                <button disabled={isCSVLoading} className='csvBtn'>{isCSVLoading ? "Loading CSV..." : "Download as CSV"}</button>
                            </CSVLink>
                        </div>
                    }
                    <div className="active-content" id="nav-tabContent">
                        {!isLoaded ?
                            <div className="loading-div">
                                <Loader />
                            </div>

                            :
                            <ul className="list-unstyled">
                                {/* Single Tab List */}
                                <li>
                                    <div className="col1">
                                        <h6>Name</h6>
                                    </div>
                                    <div className="col2">
                                        <h6>Item</h6>
                                    </div>
                                    <div className="col3">
                                        <h6>Price</h6>
                                    </div>

                                    <div className="col5">
                                        <h6>From</h6>
                                    </div>
                                    <div className="col6">
                                        <h6>To</h6>
                                    </div>
                                    <div className="col7">
                                        <h6>Time</h6>
                                    </div>
                                    <div className="col8">
                                        <h6>Time</h6>
                                    </div>
                                </li>
                                {events.map((event, idx) => {
                                    if (!event.item) {
                                        return;
                                    }
                                    return (
                                        <ActivityCard loginStatus={loginStatus} account={account} key={`ato_${idx}`} data={event} />
                                    );
                                })}
                            </ul>}
                        <div className="pagenation1">
                            {totalPage > 1 &&
                                <>
                                    <div className="prevBtn" onClick={() => handleClick('prevPage')}><i className="fas fa-arrow-left"></i></div>
                                    <div className="page">

                                        {totalPage === 1 ?
                                            <span className={`${page === 0 ? 'activePage' : ''}`}
                                                onClick={() => onChangePage(0)}
                                            >{1}</span> :
                                            totalPage === 2 ?
                                                ([0, 1].map((idx) => (
                                                    <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                        onClick={() => onChangePage(idx)}
                                                    >{idx + 1}</span>

                                                ))) :
                                                totalPage === 3 ?
                                                    ([0, 1, 2].map((idx) => (
                                                        <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                            onClick={() => onChangePage(idx)}
                                                        >{idx + 1}</span>

                                                    ))) :
                                                    (totalPage > 3 && totalPage <= 7) ?
                                                        (pageArray.map((d, idx) => (
                                                            <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                                onClick={() => onChangePage(idx)}
                                                            >{idx + 1}</span>

                                                        ))) : ([0, 1, 2].map((idx) => (
                                                            <span key={idx} className={`${idx === page ? 'activePage' : ''}`}
                                                                onClick={() => onChangePage(idx)}
                                                            >{idx + 1}</span>

                                                        )))
                                        }

                                        {page > 3 &&
                                            <span>...</span>}

                                        {page >= 2 && page < totalPage - 3 &&
                                            <>
                                                {pageArray.map((d, idx) => (
                                                    idx > 2 &&
                                                    idx >= page - 1 &&
                                                    idx < page + 2 &&
                                                    idx < totalPage - 3 &&
                                                    <span className={`${idx === page ? 'activePage' : ''}`}
                                                        onClick={() => onChangePage(idx)}
                                                    >{idx + 1}</span>

                                                ))}

                                            </>
                                        }
                                        {page < totalPage - 4 && totalPage > 7 &&
                                            <span>...</span>}


                                        {totalPage > 7 &&
                                            <>
                                                <span className={`${page === totalPage - 3 ? 'activePage' : ''}`}
                                                    onClick={() => onChangePage(totalPage - 3)}
                                                >{totalPage - 2}</span>
                                                <span className={`${page === totalPage - 2 ? 'activePage' : ''}`}
                                                    onClick={() => onChangePage(totalPage - 2)}
                                                >{totalPage - 1}</span>
                                                <span className={`${page === totalPage - 1 ? 'activePage' : ''}`}
                                                    onClick={() => onChangePage(totalPage - 1)}
                                                >{totalPage}</span>
                                            </>
                                        }

                                    </div>
                                    <div className="nextBtn" onClick={() => handleClick('nextPage')}><i className="fas fa-arrow-right"></i></div>
                                </>}
                        </div>
                    </div>


                </div>
            </div>
        </section>
    );
}

export default Activity;