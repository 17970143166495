import React from 'react'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'

import Collection from '../components/Collections/Collections'
import Footer from '../components/Footer/Footer'
import ModalSearch from '../components/Modal/ModalSearch'
import ModalMenu from '../components/Modal/ModalMenu'
import Scrollup from '../components/Scrollup/Scrollup'

function Collections(props) {
    const {filter} = props;
    return (
        <div className="main">
            <Breadcrumb title={filter === 0 ? "Collections" : "My Collections"} page="Collections" />
            <Collection {...props}/>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            <Scrollup />
        </div>
    )
}

export default Collections