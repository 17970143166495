import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./collectionCard.scss";
type PropsType = {
  data?: any;
  rate?: number;
};
export default function CollectionCard1({ data, rate }: PropsType) {
  const [collection, setCollection] = useState<any>();
  useEffect(() => {
    setCollection(data);
  }, [data, setCollection]);

  const [byVolume, setByVolume] = useState(0);
  const handleVolumeSelected = (e) => {
    setByVolume(parseFloat(e.target.value));
  };
  return (
    <div className="collectionCard1">
      <div className="meta-info mb-3">
        <div className="author">
          {/* <div className="avatar">
                    <img src={collection?.logo_uri || "/apple-touch-icon.png"} alt=""/>
                </div> */}
          <div className="info">
            <Link to={"/collections/" + collection?.name}>
              {collection?.name.length > 20
                ? collection?.name.substring(0, 20) + "..."
                : collection?.name}
            </Link>
            <div className="row d-flex justify-content-between align-items-center">
              <span className="d-flex justify-content-between align-items-start">
                <p>Items</p>
                <h6>{collection?.itemCount}</h6>
              </span>
              <span className="d-flex justify-content-between align-items-start">
                <p>Floor Price</p>
                <h6>
                  <img src="/img/icons/color_icon.png" alt="" />{" "}
                  {collection?.floorPrice}
                </h6>
              </span>
              <span className="d-flex justify-content-between align-items-end">
                <p>Volume</p>
                <h6>
                  <img src="/img/icons/color_icon.png" alt="" />
                  {byVolume === 0
                    ? collection?.volume
                    : byVolume === 1
                    ? collection?.volume_24
                    : collection?.volume_72}
                </h6>
              </span>
            </div>
          </div>
        </div>
        {/* <div className="wishlist-button volume">
            <h5 >{collection?.floorPrice}</h5>
                <span>
                <img src="/img/icons/color_icon.png" alt="" />
                <h5 >{collection?.volume}</h5>
                </span>
                
            </div> */}
      </div>

      <div className="card-media">
        <div className="left-wrap">
          <Link
            to={"/collections/" + collection?.name}
            className="nftCollection1"
          >
            <img
              src={collection?.featured_uri || "/apple-touch-icon.png"}
              alt=""
            />
          </Link>
        </div>
        {/* <div className="right-wrap">
                    <Link to={"/collections/" + collection?.name} className='nftCollection2'>
                        <img src={collection?.hotItems[0]?.assetUrl || "/apple-touch-icon.png"} alt="" />
                    </Link>
                    <Link to={"/collections/" + collection?.name} className='nftCollection3'>
                        <img src={collection?.hotItems[1]?.assetUrl || "/apple-touch-icon.png"} alt="" />
                    </Link>
                    <Link to={"/collections/" + collection?.name} className='nftCollection4'>
                        <img src={collection?.hotItems[2]?.assetUrl || "/apple-touch-icon.png"} alt="" />
                    </Link>
                </div> */}
      </div>
    </div>
  );
}
