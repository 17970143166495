import { useState } from "react";
import toast from "react-hot-toast";
//import Button from "../../MoreComponents/Button";
import ReactPlayer from "react-player";
import "./uploadFile.scss";
// type PropsType = {
//   setFileSrc(value): void;
//   setFileType(value): void;
// };
export default function UploadFile(props) {
  const { setFileSrc, setFileType, setPreview, sizeType, setPreviewURL } = props; //sizeType- 0:logo(200*200), 1:featured(350*350), 2:banner(1400*400)
  const widthArray = [200, 350, 1400];
  const heightArray = [200, 350, 400];
  const [mainFile, setMainFile] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);
  const [isShowVideo, showVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isShowPreview, showPreview] = useState(false);

  const onChangeMainFile = (e) => {
    // var files = e.target.files;
    // console.log(files)
    // if (e.target.files && e.target.files.length > 0) {
    // 	const type = e.target.files[0].type.split("/")[0];
    // 	if (type === "video") {
    // 		showVideo(true);
    // 		showPreview(true);
    // 	} else {
    // 		showVideo(false);
    // 		showPreview(false);
    // 	}
    //     if (type === "audio") {
    // 		showVideo(true);
    // 		showPreview(true);
    // 	} else {
    // 		showVideo(false);
    // 		showPreview(false);
    // 	}
    // 	setMainFile(e.target.files[0]);
    //     console.log(type)
    // }

    if (e.target.files && e.target.files.length > 0) {
      var files = e.target.files;
      const file = e.target.files[0];
      
      var filesArray = [].slice.call(files);
      filesArray.forEach((e) => {
        if (
          e.name.search("avi") >= 0 ||
          e.name.search("mpg") >= 0 ||
          e.name.search("m4v") >= 0 ||
          e.name.search("mp4") >= 0
        ) {
          setMainFile(file);
          setFileType("video");
          setFileSrc(file);
          showVideo(true);
          showPreview(true);
        }
        if (e.name.search("mp3") >= 0) {
          setMainFile(file);
          setFileType("audio");
          setFileSrc(file);
          showVideo(true);
          showPreview(true);
        }
        if (
          e.name.search("png") >= 0 ||
          e.name.search("bmp") >= 0 ||
          e.name.search("gif") >= 0 ||
          e.name.search("jpg") >= 0 ||
          e.name.search("jpeg") >= 0 ||
          e.name.search("webp") >= 0
        ) {
          var img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = function(){
            var width = img.naturalWidth;
            var height = img.naturalHeight;
            URL.revokeObjectURL(img.src);
            // if ((sizeType === 0 && width === 200 && height === 200) ||
            //     (sizeType === 1 && width === 350 && height === 350) ||
            //     (sizeType === 2 && width === 1400 && height === 400)
            // ){
            //if (width === 350 && height === 350){
              setMainFile(file);
              setPreviewURL(URL.createObjectURL(file));
              setFileType("image");
              setFileSrc(file);
              showVideo(false);
              showPreview(false);
            // }else{
            //   toast.error("Image should be 350*350px!");
            // }
          }
          
        }
      });
    }
  };

  const removeMainImage = (e) => {
    e.preventDefault();
    setMainFile("");
    setFileType("");
    setFileSrc(null);
    setIsPlaying(true);
  };
  const onChangePreviewImg = (e) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      setPreviewImg(e.target.files[0]);
      setPreview(e.target.files[0]);
      
    }
  };

  const removePreviewImg = (e) => {
    e.preventDefault();
    setPreviewImg("");
    setPreview("");
  };
  return (
    <>
      <div className="fileContainer">
        <label className="rbFileInput" htmlFor="main_file_input">
          <div className="internal-btn-upload">
            <p>
              File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV,
              OGG, GLB, GLTF. Max size: 1 MB, Dimension: 350*350px
            </p>
            <button className="explore upload-file-btn" label="Upload file" >
              Upload File
              {!mainFile ? (
                <i className="fa fa-image"  />
              ) : (
                <i className="fa fa-image rbFileIcon" />
              )}
            </button>
          </div>
          <input
            type="file"
            id="main_file_input"
            name="main_file"
            style={{ display: "none" }}
            // accept=".jpg,.png,.gif,.svg,.mp4,.webm,.mp3,.wav,.ogg,.glb,.gltf,.max"
            accept=".jpg,.png,.gif,.svg,.mp3"
            onChange={onChangeMainFile}
          />
          
          {mainFile && (
            <div className="rbFile">
              <span className={"removeImg"} onClick={removeMainImage}>
                &times;
              </span>
              {isShowVideo ? (
                <>
                  <ReactPlayer
                    width="100%"
                    style={{ position: "absolute" }}
                    height="calc(100%-50px)"
                    url={URL.createObjectURL(mainFile)}
                    playing={isPlaying}
                    controls
                  />
                  <div className="video-change" onClick={onChangeMainFile}>
                    Change
                  </div>
                </>
              ) : (
                <img
                  src={URL.createObjectURL(mainFile)}
                  width={300}
                  height={200}
                  alt=""
                />
              )}
            </div>
          )}
        </label>
      </div>
      {isShowPreview && (
        <>
          <p className="text-label">
            Preview Image<span className="text-danger">*</span>
          </p>
          <p>
            Because you’ve included multimedia, you’ll need to provide an image
            (PNG, JPG, or GIF) for the card display of your item.
          </p>
          <div className="fileContainer w-h-160">
            <label className="rbFileInput" htmlFor="preview_input">
              <input
                type="file"
                id="preview_input"
                name="main_file"
                accept=".jpg,.png,.gif,.svg"
                style={{ display: "none" }}
                onChange={(e)=>onChangePreviewImg(e)}
              />
              {!previewImg ? (
                <i className="fa fa-image" style={{ fontSize: "4.5em" }} />
              ) : (
                <i className="fa fa-image rbFileIcon" />
              )}
              {previewImg && (
                <div className="rbFile">
                  <span className={"removeImg"} onClick={removePreviewImg}>
                    &times;
                  </span>
                  <img
                    src={URL.createObjectURL(previewImg)}
                    width={160}
                    height={160}
                    alt=""
                  />
                </div>
              )}
            </label>
          </div>
        </>
      )}
    </>
  );
}
