import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { truncateActivityString, truncateHashString, truncateString } from 'utils';
import './activityCard.scss'
import moment from 'moment';
type PropsType = {
    data?: any
    loginStatus?: boolean
    account?: string

}
export default function ActivityCard({ data, loginStatus, account }: PropsType) {
    const [event, setEvent] = useState<any>()
    useEffect(() => {
        setEvent(data)
    }, [data, setEvent]);

    return (
        <li className="activityCard single-tab-list d-flex align-items-center">
            <div className="col1">
                {event?.name === 'Listed' && <i className="fas fa-tag"></i>}
                {event?.name === 'Delisted' && <i className="fas fa-bars"></i>}
                {event?.name === 'Transferred' && <i className="fas fa-arrow-right"></i>}
                {event?.name === 'Minted' && <i className="fas fa-coins"></i>}
                {event?.name === 'Purchased' && <i className="fas fa-coins"></i>}

                <p>{event?.name}</p>
            </div>
            <div className="col2">
                <Link to={"/item/" + event?.itemCollection + "/" + event?.tokenId}>
                    <img
                        className="avatar-lg"
                        src={(event?.item?.assetType === "video" || event?.item?.assetType === "audio") ? event?.item.preview : event?.item?.assetUrl}
                        alt=""
                    />
                </Link>
                <div className="activity-content ml-1">
                    <Link to={"/item/" + event?.itemCollection + "/" + event?.tokenId}>
                        <h5 className="mt-0 mb-2">{event?.item?.name}</h5>
                    </Link>
                    <p className="m-0">
                        <Link target="_blank" to={"/profile/" + event?.user.address}>{!loginStatus ? event?.user.name : event?.user.address.toLowerCase() === account?.toLowerCase() ? "Me" : ((event?.user?.name === 'No Name' || event?.user?.name === 'NoName') ?
                            (event?.user?.address.substring(2, 8) + "...").toUpperCase() :
                            (event?.user?.name.length > 10 ? event?.user?.name.substring(0, 10) + "..." : event?.user?.name))}</Link></p>
                </div>
            </div>
            <div className="col3">
                <p><img src="/img/icons/color_icon.png" alt="" /> <strong>{event?.price}</strong></p>
            </div>

            <div className="col5">
                <Link target="_blank" to={"/profile/" + event?.from}>
                    <p>{truncateActivityString(event?.from)}</p>
                </Link>
            </div>
            <div className="col6">
                <Link target="_blank" to={"/profile/" + event?.to}>
                    <p>{truncateActivityString(event?.to)}</p>
                </Link>
            </div>
            <div className="col7">
                <p>{moment(event?.timestamp * 1000).fromNow()}</p>
                {/* <p> {Math.round((Math.floor(new Date().getTime() / 1000) - parseFloat(event?.timestamp)) / (3600 * 24)) > 24 ? `${Math.round((Math.floor(new Date().getTime() / 1000) - parseFloat(event?.timestamp)) / (3600 * 24))} days ago` : `${Math.round((Math.floor(new Date().getTime() / 1000) - parseFloat(event?.timestamp)) / (3600))} hours ago`}</p> */}
            </div>
            <div className="col8">
                <p>{moment(event?.timestamp * 1000).fromNow()}</p>
                {/* <p> {Math.round((Math.floor(new Date().getTime() / 1000) - parseFloat(event?.timestamp)) / (3600 * 24)) > 24 ? `${Math.round((Math.floor(new Date().getTime() / 1000) - parseFloat(event?.timestamp)) / (3600 * 24))} days ago` : `${Math.round((Math.floor(new Date().getTime() / 1000) - parseFloat(event?.timestamp)) / (3600))} hours ago`}</p> */}
            </div>

        </li>
    )
}
