import './loader.scss';

export default function Loader() {
  return (
    <div className="spinner-box">
        <div className="three-quarter-spinner"></div>
    </div>
  )
  
}
