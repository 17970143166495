import "./sideBar.scss"
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { truncateWalletString } from "../../utils";
import { connectors, connectorLocalStorageKey } from "../../utils/connectors";
import Expand from "react-expand-animated";
type MenuType = {
    expanded: boolean;
    setExpanded(flag: boolean): void;
};

export default function SideBarMenu({ expanded, setExpanded }: MenuType) {
    const { connector, library, account, active, chainId, deactivate } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    let newVariable = process.env.REACT_APP_NETWORK_ID;
    useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId, newVariable]);



    const [user, setUser] = useState(undefined);

    useEffect(() => {
        if (loginStatus && !user) {
        axios.get(`/api/user/${account}`)
            .then(async res => {
            setUser(res.data.user);
            })
            .catch(err => {
            setUser(undefined);
            })
        }
    }, [loginStatus, user, account]);


    const signOut = () => {
        connectors.map((connector) => {
        deactivate();
        })
        window.localStorage.setItem(connectorLocalStorageKey, "");
        window.location.href = "/";
    }
    const styles = {
        open: { width: "100%" },
        close: { width: "100%" }
    };
    const transitions = ["height", "opacity", "background"];
    const [isCreateExpand, setIsCreateExpand] = useState(false);
    const [isProfileExpand, setIsProfileExpand] = useState(false);
    return (
        <div className={"sideBar " + (expanded ? "expandSide": "smallSide")}
        
        >
            <div className="menu menu-div header" >
                <div className="modal-header" data-dismiss="modal"onClick={()=>setExpanded(false)}>
                    Menu <i className="far fa-times-circle icon-close"  />
                </div>
            <ul className="navbar-nav items mx-auto">
                <li className="nav-item " onClick={()=>setExpanded(false)}>
                <NavLink className="nav-link" to="/">
                    Home
                </NavLink>
                </li>
                <li className="nav-item" onClick={()=>setExpanded(false)}>
                <NavLink to="/explore" className="nav-link">
                    Explore
                </NavLink>
                </li>
                <li className="nav-item" onClick={()=>setExpanded(false)}>
                <NavLink to="/collections" className="nav-link">
                    Collections
                </NavLink>
                </li>
                <li className="nav-item" onClick={()=>setExpanded(false)}>
                <NavLink to="/activity" className="nav-link">
                    Activity
                </NavLink>
                </li>

                {
                loginStatus &&
                <li className="nav-item dropdown" onClick={()=>setIsCreateExpand(!isCreateExpand)}>
                    <div className="nav-link mb-3">
                    Create <i className="fas fa-angle-down ml-1" />
                    </div>

                    <Expand
                        open={isCreateExpand}
                        duration={300}
                        styles={styles}
                        transitions={transitions}
                    >
                        <ul className="sub-menu" onClick={()=>setExpanded(false)}>
                            <li className="">
                                <NavLink to="/create" className="">
                                Item
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink to="/collection/create" className="">
                                Collection
                                </NavLink>
                            </li>
                        </ul>

                    </Expand>

                    
                </li>
                }
                {loginStatus && <li className="nav-item" onClick={()=>setExpanded(false)}>
                <NavLink to="/import" className="nav-link">
                    Import
                </NavLink>
                </li>}
                {
                loginStatus && 
                <li className="nav-item dropdown" onClick={()=>setIsProfileExpand(!isProfileExpand)}>
                    <div className="nav-link mb-3">
                    {
                        <img
                        src={user ? user?.logo_url : "/avatar.png"}
                        className="avatar-sm rounded-circle" alt="" />
                    }
                    <i className="fas fa-angle-down ml-1" />
                    </div>
                    <Expand
                        open={isProfileExpand}
                        duration={300}
                        styles={styles}
                        transitions={transitions}
                    >
                        <ul className="sub-menu" onClick={()=>setExpanded(false)}>
                            <li className="">
                                <NavLink to={'/profile/' + account} className="">
                                {
                                    <img
                                    src={user ? user?.logo_url : "/avatar.png"}
                                    className="avatar-sm rounded-circle" alt=""
                                    style={{ width: '30px', height: '30px' }} />
                                }
                                Profile
                                </NavLink>
                            </li>
                            
                            <li className="">
                                <div onClick={() => signOut()} className="">
                                <i className="fa fa-sign-out-alt" />
                                SignOut
                                </div>
                            </li>
                        </ul>

                    </Expand>
                    
                </li>
                }

            </ul>
            {/* Navbar Action Button */}


            {!loginStatus && <ul className="navbar-nav action">
                <li className="nav-item ml-3">
                <NavLink
                    to="/wallet-connect"
                    className="btn ml-lg-auto btn-bordered-white"
                >
                    <i className="icon-wallet mr-md-2" />
                    Wallet Connect
                </NavLink>
                </li>
            </ul>}
            {
                loginStatus && <ul className="navbar-nav d-none d-md-block d-lg-block ">
                <li className="nav-item ml-3">
                    <div className="btn ml-lg-auto btn-bordered-white">
                    {truncateWalletString(account)}
                    </div>
                </li>
                </ul>
            }

            </div>
        </div>
    )
}

