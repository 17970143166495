import { spawn } from 'child_process';
import BtnTimer from 'components/timer/BtnTimer';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './nftCard1.scss'
type PropsType = {
    data?: any
    rate?: number
    isPreview?: boolean
    isHome?: boolean
}
export default function NFTCard1({ data, rate, isPreview, isHome }: PropsType) {
    const [item, setItem] = useState<any>()
    useEffect(() => {
        setItem(data)
    }, [data, setItem]);

    return (
        <div className='nftCard1'>
            <div className="card-media">

                {isPreview ?
                    <Link to='' className='nftCollection'>
                        <img src={item?.assetUrl || "/apple-touch-icon.png"} alt="" />
                    </Link> :

                    <Link to={`/item/${item?.itemCollection}/${item?.tokenId}`} className='nftCollection'>
                        {
                            item?.assetType === "image" ? <img src={item?.assetUrl || "/apple-touch-icon.png"} alt="" />
                            :<img src={item?.preview || "/apple-touch-icon.png"} alt="" />
                        }
                    </Link>}
                {item?.timestamp > Date.now() ?

                    <div className="featured-countdown">
                        <span className="slogan">
                            <i className="fas fa-fire"></i>
                        </span>
                        <BtnTimer deadLine={item?.timestamp} />
                    </div> : null}
                {/* {!isPreview &&
                    <div className="button-place-bid">
                        <button className="sc-button style-place-bid style bag fl-button pri-3">
                            <i className="fas fa-shopping-bag"></i>
                            <span>BUY</span>
                        </button>
                    </div>
                } */}

            </div>
            <div className="card-title">
                <h5>
                    <Link to={`/item/${item?.itemCollection}/${item?.tokenId}`}>
                        {item?.name}

                    </Link>
                </h5>

            </div>
            <div className="line c-b"></div>
            <div className="cardFoot mt-2">
                <div className="price">
                    {item?.pair ?
                        <>
                            <span>For Sale</span>
                            <h5> <img src="/img/icons/color_icon.png" alt="" /> {item?.pair.price}</h5>
                        </> :
                        <span>Not Listed</span>}

                </div>
                <div className='usd'>
                    <p>USD {(rate * item?.pair.price).toFixed(2)}</p>
                </div>
            </div>

        </div>
    )
}
