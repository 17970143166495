import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { ethers } from "ethers";

import CancelListPage from '../Sale/CancelListPage';
import { buy, delistItem, onTransfer } from '../../utils/contracts';
import toast from 'react-hot-toast';
import TransferPage from '../Sale/TransferPage';
import ReactPlayer from 'react-player';
import './itemDetails.scss'
import PropertiesTable from './PropertiesTable';
import HistoryCard from 'components/card/historyCard/HistoryCard';
import ListItemPage from 'components/Sale/ListItemPage';
import BuyConfirmPage from 'components/Sale/BuyConfirmPage';
import { TwitterShareButton, TwitterIcon, } from 'react-share';
import { baseApiUrl } from 'utils';
const ItemDetails = () => {

    const { connector, library, account, active, chainId } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    let newVariable = process.env.REACT_APP_NETWORK_ID;
    useEffect(() => {
        const isLoggedin =
            account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId, newVariable]);


    const [balance, setBalance] = useState(0);
    useEffect(() => {
        if (loginStatus) fetchBalance();
    }, [loginStatus]);

    const fetchBalance = async () => {
        if (loginStatus) {
            const rawBalance = await library.getBalance(account);
            const value = parseFloat(ethers.utils.formatEther(rawBalance));
            setBalance(value);
        }
        return () => {
            setBalance(0);
        };
    };

    const [item, setItem] = useState<any>();
    const [rate, setRate] = useState<number>(0);
    const location = useLocation();

    useEffect(() => {
        if (!item) {
            fetchItem();
        }
    }, [item]);

    function fetchItem() {
        const urlData = location.pathname.toString();
        const len = urlData.split("/").length;
        const collectionAddress = urlData.split("/")[len - 2];
        const tokenId = urlData.split("/")[len - 1];
        axios
            .get(`/api/item/${collectionAddress}/${tokenId}`)
            .then((res) => {
                setItem(res.data.item);
                setRate(res.data.rate);
            })
            .catch((err) => {
                //show an error page that the item doesnt exist
                setItem(undefined);
                setRate(0);
            });
    }
    // Listing
    const [showListingModal, setShowListingModal] = useState(false);
    function onListingClose(isListed) {
        setShowListingModal(false);
        if (isListed) fetchItem();
    }

    // Cancel Listing
    const [showCancelListingModal, setShowCancelListingModal] = useState(false);
    function onCancelListingClose() {
        setShowCancelListingModal(false);
    }

    // Transferring
    const [transferModal, setTransferModal] = useState(false);
    async function onTransferClose(toAddress) {
        if (toAddress === "") return;
        setTransferModal(false);
        if (loginStatus) {
            const load_toast_id = toast.loading("Please wait");
            setIsLoading(true);
            onTransfer(item?.collection.address, account, toAddress, item?.tokenId, chainId, library.getSigner()).then((res) => {
                if (res) {
                    axios
                        .get(`/api/syncCollection`, { params: { address: item?.collection.address } })
                        .then((res) => {
                            setIsLoading(false);
                            toast.dismiss(load_toast_id);
                            toast.success("Transferred Successfully!");
                            window.location.reload();
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            toast.dismiss(load_toast_id);
                            toast.error("Transferring Failed!");
                        });

                } else {
                    setIsLoading(false);
                    toast.dismiss(load_toast_id);
                    toast.error("Transferring Failed!");
                }
            })
        }
    }

    const [isLoading, setIsLoading] = useState(false);
    const onCancelListing = () => {
        setIsLoading(true);
        const load_toast_id = toast.loading("Please wait");
        if (item?.pair) {
            delistItem(item?.pair.id, chainId, library.getSigner()).then((res) => {
                if (res) {
                    axios
                        .get(`/api/syncCollection`, { params: { address: item?.collection.address } })
                        .then((res) => {
                            setIsLoading(false);
                            window.location.reload();
                            toast.dismiss(load_toast_id);
                            toast.success("Listing cancelled successfully");
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            toast.dismiss(load_toast_id);
                            toast.error("Unlisting failed");
                        });
                } else {
                    setIsLoading(false);
                    toast.dismiss(load_toast_id);
                    toast.error("Unlisting failed");
                }
            });
        }
    };

    //Buy Now
    const [showBuyModal, setShowBuyModal] = useState(false);
    function onBuyClose() {
        setShowBuyModal(false);
    }

    function submitBuy() {
        if (!loginStatus) {
            toast.error("Please connect your wallet.");
            return;
        }
        if (!item.pair) {
            toast.error("Item Buy is disabled");
            return;
        }
        if (balance < item.pair.price) {
            toast.error("Your available balance is less than the price!");
            return;
        }
        setIsLoading(true);
        const load_toast_id = toast.loading("Please wait...");
        buy(
            account,
            item.pair.id,
            item.pair.price,
            item.royalty * 10,
            chainId,
            library.getSigner()
        ).then((tokenId) => {
            if (tokenId) {
                axios
                    .get(`/api/syncCollection`, { params: { address: item?.collection.address } })
                    .then((res) => {
                        setIsLoading(false);
                        setShowBuyModal(false);
                        toast.dismiss(load_toast_id);
                        toast.success("Bought Successfully");
                        window.location.reload();
                        return true;
                    })
                    .catch((error) => {
                        if (error.response) {
                            toast.dismiss(load_toast_id);
                            toast.error("Failed Transaction");
                            setIsLoading(false);
                            setShowBuyModal(false);
                        }
                    });
            } else {
                toast.dismiss(load_toast_id);
                toast.error("Failed Transaction");
                setIsLoading(false);
                setShowBuyModal(false);
            }
        });
    }

    return (
        <section className="item-details-area">
            <div className="container-div">
                <div className="col-div row justify-content-between">
                    <div className="left">
                        <div className="item-info">
                            <div className="item-thumb text-center">
                                {(item?.assetType === "video" || item?.assetType === "audio") ?
                                    <video src={item?.assetUrl} controls width="100%"
                                        height="300px" ></video>
                                    : (
                                        <img
                                            className="card-img-top"
                                            src={item?.assetUrl}
                                            alt=""
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        {/* Content */}
                        <div className="content mt-5 mt-lg-0">
                            <div className="row d-flex align-items-center justify-content-between">
                                <h3 className="mt-0 mb-0">{item?.name}{item?.rarity >= 0 ? "(Rarity : " + item?.rarity + ")" : ""}</h3>
                                {item?.pair && <div className="myDropdown">
                                    <button className='shareBtn'><i className="fas fa-share-alt"></i></button>
                                    <ul className="myDropdown-menu">
                                        <li className="nav-item">

                                            <TwitterShareButton
                                                title={`${item?.name} has been listed on Epic Gallery for ${item?.pair.price}MTV`}
                                                hashtags={['MultiVAC', 'nft', 'epicgallery']}
                                                url={baseApiUrl + "/item/" + item?.itemCollection + "/" + item?.tokenId}
                                            >
                                                Share via Twitter <TwitterIcon size={32} round />
                                            </TwitterShareButton>
                                        </li>
                                    </ul>
                                </div>}



                            </div>


                            {/* Owner */}
                            <div className="col-div row mb-3">
                                <div className="card-div owner d-flex align-items-center mr-10">
                                    <Link className="owner-meta d-flex align-items-center ml-3" to={"/profile/" + item?.ownerUser.address}>
                                        <img className="avatar-sm rounded-circle" src={item?.ownerUser.logo_url} alt="" />
                                        <span className="ml-2">
                                            <p>Owned By</p>
                                            <h6>@{!loginStatus ? item?.ownerUser.name : item?.ownerUser.address.toLowerCase() === account.toLowerCase() ? "Me" : (
                                                (item?.ownerUser?.name === 'No Name' || item?.item?.name === 'NoName') ?
                                                    (item?.ownerUser?.address.substring(2, 8) + "...").toUpperCase() :

                                                    (item?.ownerUser?.name.length > 10 ? item?.ownerUser?.name.substring(0, 10) + "..." : item?.ownerUser?.name)
                                            )
                                            }</h6>
                                        </span>
                                    </Link>
                                </div>
                                <div className="card-div owner d-flex align-items-center">
                                    <Link className="owner-meta d-flex align-items-center ml-3" to={"/collections/" + item?.collection.name}>
                                        <img className="avatar-sm rounded-circle" src={item?.collection.logo_uri} alt="" />
                                        <span className="ml-2">
                                            <p>Collection</p>
                                            <h6>{item?.collection.name}</h6>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <p className='mt-0 mb-3'>{item?.description}</p>
                            {/* Item Info List */}
                            <div className="row item-info-list">
                                <div className="card-div d-flex justify-content-center align-items-center">
                                    <div className='row'>
                                        <p>{!item?.pair ? "Not Listed" : "Current Price : "}</p>
                                        <div className='row'>
                                            <h6>{item?.pair && item?.pair.price + "MTV"}</h6>
                                            <p>{item?.pair && "=" + (parseFloat(item?.pair.price) * (rate)).toFixed(2) + "USD"} </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="card-div">
                                        <div className='row'>
                                            <p>Count Down</p>
                                            <div className='row'>
                                                <BtnTimer deadLine={item?.timestamp} setShowMint={function (value: boolean): void {
                                                    throw new Error('Function not implemented.');
                                                } }/>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>

                            {
                                loginStatus && item?.itemOwner.toLowerCase() === account.toLowerCase() && !item?.pair && item?.collection.isApproved &&
                                <div className="d-block btn btn-bordered-white mt-4" onClick={() => setShowListingModal(true)}><i className="fab fa-sellcast"></i> Sell</div>
                            }
                            {
                                loginStatus && item?.itemOwner.toLowerCase() === account.toLowerCase() && !item?.pair &&
                                <div className="d-block btn btn-bordered-white mt-4" onClick={() => setTransferModal(true)}><i className="fas fa-exchange"></i> Transfer</div>
                            }
                            {
                                loginStatus && item?.itemOwner.toLowerCase() === account.toLowerCase() && item?.pair &&
                                <div className="d-block btn btn-bordered-white mt-4" onClick={() => setShowCancelListingModal(true)}><i className="fas fa-align-slash"></i> Cancel Listing</div>
                            }
                            {
                                loginStatus && item?.itemOwner.toLowerCase() !== account.toLowerCase() && item?.pair &&
                                <div className="d-block btn btn-bordered-white mt-4" onClick={() => setShowBuyModal(true)}><i className="fas fa-shopping-bag"></i> Buy</div>
                            }
                        </div>
                        {/* Netstorm Tab */}
                        <ul className="netstorm-tab nav nav-tabs" id="nav-tab">
                            <li>
                                <Link className={(location.hash === "" || location.hash === "#nav-property") && "active"} id="nav-property-tab" data-toggle="pill" to="#nav-property">
                                    <h5 className="m-0">Traits</h5>
                                </Link>
                            </li>
                            <li>
                                <Link className={location.hash === "#nav-history" && "active"} id="nav-history-tab" data-toggle="pill" to="#nav-history">
                                    <h5 className="m-0">History</h5>
                                </Link>
                            </li>
                        </ul>
                        {/* Tab Content */}
                        <div className="tab-content" id="nav-tabContent">
                            {location.hash === "#nav-history" && <div className="tab-pane fade show active" id="nav-history">
                                <ul className="list-unstyled">
                                    {/* Single Tab List */}
                                    {item?.events.map((event, idx) => {
                                        return (
                                            <HistoryCard
                                                key={idx}
                                                event={event}
                                                loginStatus={loginStatus}
                                                account={account}
                                                rate={rate}
                                            />
                                        );
                                    })}
                                </ul>
                            </div>}
                            {(location.hash === "" || location.hash === "#nav-property") && item && <PropertiesTable item={item} />}
                        </div>
                    </div>
                </div>
            </div>
            {showListingModal && (
                <ListItemPage
                    onClose={onListingClose}
                    balance={balance}
                    nftFee={0}
                    item={item}
                    rate={rate}
                />
            )}
            {transferModal && (
                <TransferPage
                    onClose={onTransferClose}
                />
            )}
            {showCancelListingModal && (
                <CancelListPage
                    onClose={onCancelListingClose}
                    onSubmit={onCancelListing}
                    balance={balance}
                // nftFee={0}
                // item={item}
                // rate={rate}
                />
            )}
            {showBuyModal && (
                <BuyConfirmPage
                    onClose={onBuyClose}
                    onSubmit={submitBuy}
                // balance={balance}
                // nftFee={0}
                />
            )}
        </section>
    );
}

export default ItemDetails;