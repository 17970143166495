import Footer from "../components/Footer/Footer";
import ModalSearch from "../components/Modal/ModalSearch";
import Scrollup from "../components/Scrollup/Scrollup";
import Hero from "components/Hero/Hero";
import Collections from "components/Collections/Collections";
import Explorer from "components/Explore/Explorer";
import Work from "components/Work/Work";
import TopSeller from "components/TopSeller/TopSellerOne";

export default function Home() {
    return (
      <div className="main">
        <Hero />
        <Collections filter={2} />
        <Explorer showMethod={3} />
        <TopSeller />
        {/* <Work /> */}
        <Footer />
        <ModalSearch />
        <Scrollup />
      </div>
    );
}
