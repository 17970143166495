import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
import { getIpfsHashFromFile } from "../../utils/ipfs";
import axios from "axios";
import toast from "react-hot-toast";
import { createNewCollection } from '../../utils/contracts';
import './createCollection.scss'
import CollectionCard1 from 'components/card/collectionCard/CollectionCard1';

const CATEGORIES = [
    { value: "art", title: "Art" },
    { value: "music", title: "Music" },
    { value: "virtual worlds", title: "Virtual Worlds" },
    { value: "sports", title: "Sports" },
    { value: "utility", title: "Utility" },
]
interface MyDataType {
    logo_uri?: string;
    featured_uri ? : string
    name?: string;
    ownerUser? : {
        name ?: string;
    }
    hotItems? : [
        {assetUrl ?: string;},
        {assetUrl ?: string;},
        {assetUrl ?: string;},
        {assetUrl ?: string;},
    ]
  }

function CollectionForm(props) {
    const [logo, setLogo] = useState();
    const [logo_uri, setLogoUri] = useState(null);
    const [FeaturedImg, setFeaturedImg] = useState();
    const [featured_uri, setFeaturedUri] = useState(null);
    const [banner_uri, setBannerUri] = useState("");
    const [BannerImg, setBannerImg] = useState();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("art");
    const [discord, setDiscord] = useState("");
    const [instagram, setInstagram] = useState("");
    const [tme, setTme] = useState("");
    const [fee, setFee] = useState(0);
    const [isExplicit, setIsExplicit] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [volume, setVolume] = useState(0);
    const { isCreate, collectionName } = props;
    const { connector, library, chainId, account, active } = useWeb3React();
    const [loginStatus, setLoginStatus] = useState(false);
    const newVariable = process.env.REACT_APP_NETWORK_ID;
    useEffect(() => {
        const isLoggedin =
            account && active && chainId === parseInt(newVariable, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId, newVariable]);

    const [collection, setCollection] = useState(null);
    let tempName = collectionName;
    useEffect(() => {
        if (!collection && !isCreate && collectionName) {

            axios
                .get(`/api/collection/detail/${tempName}`)
                .then((res) => {
                    setCollection(res.data.collection);
                })
                .catch((err) => {
                    setCollection(null);
                });

        }
    }, [collection, collectionName, isCreate, tempName]);


    const [createdData, setCreatedData]  = useState<any>()
  
    useEffect(() => {
        var data = {
            logo_uri: logo ? URL.createObjectURL(logo):"/apple-touch-icon.png",
            featured_uri  : FeaturedImg ? URL.createObjectURL(FeaturedImg) : "/apple-touch-icon.png",
            name: name,
            volume : volume,
            hotItems : [
                {assetUrl : FeaturedImg ? URL.createObjectURL(FeaturedImg) : "/apple-touch-icon.png"},
                {assetUrl : FeaturedImg ? URL.createObjectURL(FeaturedImg) : "/apple-touch-icon.png"},
                {assetUrl : FeaturedImg ? URL.createObjectURL(FeaturedImg) : "/apple-touch-icon.png"},
            ],
                
        }
        console.log(FeaturedImg, featured_uri)
        setCreatedData(data);
  }, [setCreatedData, logo, name, volume, FeaturedImg]);


    useEffect(() => {
        if (collection && !isLoaded) {
            setName(collection?.name);
            setDescription(collection?.description);
            setDiscord(collection?.social_discord_link);
            setInstagram(collection?.social_instagram_link);
            setTme(collection?.social_telegram_link);
            setLogoUri(collection?.logo_uri);
            setBannerUri(collection?.banner_uri);
            setFeaturedUri(collection?.featured_uri);
            setCategory(collection?.category[0]);
            setIsExplicit(collection?.category[1] === "sensitive");
            setFee(collection?.royalty);
            setIsLoaded(true);
        }
    });


    const logoChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setLogo(e.target.files[0]);
            setLogoUri("");
        }
    };
    const FeaturedImgChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFeaturedImg(e.target.files[0]);
            setFeaturedUri("");
        }
    };
    const BannerImgChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setBannerImg(e.target.files[0]);
            setBannerUri("");
        }
    };
    // const removeImg = (img) => {
    //     switch (img) {
    //         case "BannerImg":
    //             setBannerImg("");
    //             break;
    //         case "FeaturedImg":
    //             setFeaturedImg("");
    //     }
    // };
    const nameHandle = (e) => {
        setName(e.target.value);
    };
    const descriptionHandle = (e) => {
        setDescription(e.target.value);
    };
    const categoryHandle = (e) => {
        setCategory(e.target.value);
    };
    const feeHandle = (e) => {

        setFee(parseFloat(e.target.value));
    };
    const discordHandle = (e) => {
        setDiscord(e.target.value);
    };
    const instagramHandle = (e) => {
        setInstagram(e.target.value);
    };
    const tmeHandle = (e) => {
        setTme(e.target.value);
    };
    // const blockchainHandle = (val) => {
    // 	setBlockchain(val);
    // }

    async function onCreateCollection() {
        if (!loginStatus) {
            toast.error("Please connect your wallet correctly!");
            return;
        }
        if (isCreate) {
            if (
                (isCreate && !logo) ||
                (!isCreate && (!collection.logo_uri || !logo))
            ) {
                toast.error("Logo Image is required!");
                return;
            }

            if (
                (isCreate && !BannerImg) ||
                (!isCreate && (!collection.banner_uri || !BannerImg))
            ) {
                toast.error("Banner Image is required!");
                return;
            }
            if (
                (isCreate && !FeaturedImg) ||
                (!isCreate && (!collection.featured_uri || !FeaturedImg))
            ) {
                toast.error("Feature Image is required!");
                return;
            }
        }

        if (!name) {
            toast.error("Collection Name is required");
            return;
        }

        if (!description) {
            toast.error("Collection Description is required");
            return;
        }

        if (!fee){
            toast.error("The Royalty is required");
            return;
        }

        if (fee < 0 || fee > 100){
            toast.error("The royalty should be between 0 and 100");
            return;
        }
        const load_toast_id = toast.loading("Please wait...");
        try {
            let logo_uri = isCreate ? "" : collection.logo_uri;
            if (logo !== "") {
                let logo_hash = await getIpfsHashFromFile(logo);
                logo_uri = `https://mtv_color.mypinata.cloud/ipfs/${logo_hash}`;
            }
            let banner_uri = isCreate ? "" : collection.banner_uri;
            if (BannerImg !== "") {
                let banner_hash = await getIpfsHashFromFile(BannerImg);
                banner_uri = `https://mtv_color.mypinata.cloud/ipfs/${banner_hash}`;
            }
            let featured_uri = isCreate ? "" : collection.featured_uri;
            if (FeaturedImg !== "") {
                let featured_hash = await getIpfsHashFromFile(FeaturedImg);
                featured_uri = `https://mtv_color.mypinata.cloud/ipfs/${featured_hash}`;
            }
            let collection_address = "";
            if (isCreate) {
                collection_address = await createNewCollection(
                    chainId,
                    library.getSigner()
                );
            } else {
                collection_address = collection.address;
            }
            let metaData = {
                address: collection_address,
                logo_uri: logo_uri,
                banner_uri: banner_uri,
                featured_uri: featured_uri,
                name: name,
                description: description,
                category: isExplicit
                    ? category === ""
                        ? ["sensitive"]
                        : [category, "sensitive"]
                    : [],
                social_discord_link: discord,
                social_instagram_link: instagram,
                social_telegram_link: tme,
                royalty: fee,
            };
            await axios
                .post(`/api/collection/update/`, metaData)
                .then((res) => {
                    tempName = name;
                    setCollection(res.data.collection);
                    const msg = isCreate ? "Created" : "Updated";
                    toast.success("NFT Collection is " + msg + " Successfully");
                    toast.dismiss(load_toast_id);
                    if (isCreate) props.history.push(`/collections`);
                    else props.history.push(`/collections/${res.data.collection.name}`);
                })
                .catch((err) => {
                    console.log(err);
                    toast.dismiss(load_toast_id);
                });
        } catch (err) {
            console.log(err);
            toast.dismiss(load_toast_id);
            toast.error("NFT Collection Creation is failed!");
        }
    }
    return (
        <>
            {/* Breadcrumb  */}
            <section
                style={{
                    background: BannerImg ? `url(${URL.createObjectURL(BannerImg)}) no-repeat scroll top center/cover ` :
                        (banner_uri && banner_uri !== "") ? `url(${banner_uri} no-repeat scroll top center/cover ` :
                            'url(/img/inner_bg.jpg) scroll top center / cover '
                }}
                className="breadcrumb-area overlay-dark d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Breadcrumb Content */}
                            <div className="breadcrumb-content text-center">
                                <h2 className="m-0">{isCreate ? "Create New" : "Edit My"} Collection</h2>
                                <ol className="breadcrumb d-flex justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to={`/collections`}>Collections</Link></li>
                                    <li className="breadcrumb-item active">{isCreate ? "Create New" : "Edit My"} collection</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="createCollection author-area">
                <div className="container-div">
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-3">
                            {/* Preview Window */}
                            <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                                <div className="intro-content">
                                    <h3 className="mt-3 mb-0">Preview</h3>
                                </div>
                            </div>
                            <CollectionCard1 data = {createdData}/>
                        </div>
                        <div className="col-12 col-md-8">
                            {/* Intro */}
                            <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                                <div className="intro-content">
                                    <h3 className="mt-3 mb-0">{isCreate ? "Create New" : "Edit My"} Collection</h3>
                                </div>
                            </div>
                            {/* Collection Form */}
                            <div className="item-form card no-hover">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="area-upload">
                                            <label className='text-white pl-2 mb-2'>Logo</label>
                                            <div className="input-group form-group">
                                                <div className="custom-file">
                                                    <input type="file" required onChange={(e) => logoChange(e)} className="custom-file-input  border-input" id="inputGroupFile01" />
                                                    <label className="custom-file-label  border-input" htmlFor="inputGroupFile01">Logo Image (350 x 350 recommended.)</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="area-upload">
                                            <label className='text-white pl-2 mb-2'>Banner</label>
                                            <div className="input-group form-group">
                                                <div className="custom-file">
                                                    <input type="file" required onChange={(e) => BannerImgChange(e)} className="custom-file-input" id="inputGroupFile01" />
                                                    <label className="custom-file-label border-input" htmlFor="inputGroupFile01"> Banner image (1400 x 400 recommended)</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="area-upload">
                                            <label className='text-white pl-2 mb-2'>Featured</label>
                                            <div className="input-group form-group">
                                                <div className="custom-file">
                                                    <input type="file" required onChange={(e) => FeaturedImgChange(e)} className="custom-file-input" id="inputGroupFile01" />
                                                    <label className="custom-file-label border-input" htmlFor="inputGroupFile01"> Featured image (600 x 600 recommended)</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                        <label className='text-white pl-2 mb-2'>Title</label>
                                            <input type="text" className="form-control border-input" onChange={(e) => nameHandle(e)} value={name} name="name" placeholder="Name" required />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                        <label className='text-white pl-2 mb-2'>Volume</label>
                                            <input type="number" className="form-control border-input" onChange={(e) => setVolume(parseFloat(e.target.value))} value={volume.toString()} name="name" placeholder="Name" required />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                        <label className='text-white pl-2 mb-2'>description</label>
                                            <textarea className="form-control border-input" onChange={(e) => descriptionHandle(e)} value={description} name="description" placeholder="Description" cols={30} rows={3} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group mb-3 border-input">
                                            <div className="input-group-prepend bg-dark">
                                                <label className="input-group-text text-secondary bg-dark border-0" htmlFor="inputGroupSelect01">Category</label>
                                            </div>
                                            <select onChange={(e) => categoryHandle(e)} className="custom-select bg-dark border-0" id="inputGroupSelect01">
                                                {
                                                    CATEGORIES.map((_category, idx) => {
                                                        return <option key={idx} selected={category === _category.value} value={_category.value}>{_category.title}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="form-group ">
                                            <label htmlFor="exampleFormControlInput1" className='text-white ml-1 mb-2'>Links</label>
                                            <div className=" border-input">
                                            <input type="text" value={discord} onChange={(e) => discordHandle(e)} className="form-control" id="exampleFormControlInput1" placeholder="https://discord.gg/" />
                                            <input type="text" value={instagram} onChange={(e) => instagramHandle(e)} className="form-control" id="exampleFormControlInput1" placeholder="https://yourwebsite.com" />
                                            <input type="text" value={tme} onChange={(e) => tmeHandle(e)} className="form-control" id="exampleFormControlInput1" placeholder="https://twitter.com/" />
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlInput1">Creator Earnings</label>
                                            <small className='text-muted mt-1'>Collect a fee when a user re-sells an item you originally created. This is deducted from the final sale price and paid monthly to a payout address of your choosing</small>
                                            <input type="number" min={0} value={fee} onChange={(e) => feeHandle(e)} className="form-control   border-input" id="exampleFormControlInput1" placeholder="e.g 2.5" />

                                        </div>
                                    </div>
                                    {/* <div className="col-12">
                                        <div className="form-group mt-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" onChange={(e) => setIsExplicit(!isExplicit)} checked={isExplicit} type="checkBox" name="inlineRadioOptions" id="inlineRadio2" />
                                                <label className="form-check-label" htmlFor="inlineRadio2">Explicit & sensitive content</label>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4" onClick={() => onCreateCollection()}>{isCreate ? "Create" : "Edit"} Collection</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    );
}

export default CollectionForm;