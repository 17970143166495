import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss'
type PropsType = {
    title ? :string
    subpage ? :string
    page ?: string
    featured ? : string
}
export default function Breadcrumb({title, subpage, page, featured}:PropsType) {
    const [backImgPath, setBakcImgPaht] = useState('')
    useEffect(() => {
        
        setBakcImgPaht(featured ? `url(${featured})` : 'url(/img/inner_bg.jpg)')
    }, [featured, setBakcImgPaht]);
    
    return (
        <section style={{ background: `${backImgPath}`}} className="breadcrumb-area overlay-dark d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Breamcrumb Content */}
                        <div className="breadcrumb-content text-center">
                            <h2 className="m-0">{title}</h2>
                            <ol className="breadcrumb d-flex justify-content-center">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                {
                                    subpage && <li className="breadcrumb-item"><Link to={`/${subpage}`}>{subpage}</Link></li>
                                }
                                <li className="breadcrumb-item active">{page}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
