import { Component } from 'react';


import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ItemDetail from '../components/ItemDetails/ItemDetails';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import Scrollup from '../components/Scrollup/Scrollup';

class ItemDetailsPage extends Component {
    render() {
        return (
            <div className="main">
                <Breadcrumb title="Item Details" subpage="Explore" page="Item Details" />
                <ItemDetail />
                <Footer />
                <ModalSearch />
                <Scrollup />
            </div>
        );
    }
}

export default ItemDetailsPage;