import { Button } from "@material-ui/core";
import { Modal } from "@mui/material";
import './style.scss'
export default function BuyConfirmPage({ onClose, onSubmit }) {

  const handleClose = () => {
    onClose();
  };

  const onBuy = () => {
    handleClose();
    onSubmit();
  };
  return (
    <Modal
      className="myModal buyConfirmPage"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={true}
      // onClose={(event, reason) => {
      //   if (reason === "backdropClick") {
      //     return false;
      //   }
      //   if (reason === "escapeKeyDown") {
      //     return false;
      //   }
      // }}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div

        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}

        className="modal-content p-5 col-lg-5 col-md-8 col-12">
        <div className="title text-white mb-3">
          Are you sure you want to buy this NFT Item?
        </div>
        <div className="line c-w"></div>
        <div className="row-div">
          <p>

          </p>
        </div>
        <br />
        <div className="btns">
          <Button
            className="mind mx-3"
            onClick={handleClose}
            variant="contained"
          >
            Never mind
          </Button>
          <Button
            className="buy mx-3"
            onClick={onBuy}
            variant="contained"
          >
            Buy
          </Button>
        </div>
      </div>
    </Modal>
  );
}
