import Explorer from '../components/Explore/Explorer'
import Footer from '../components/Footer/Footer'
import ModalSearch from '../components/Modal/ModalSearch'
import Scrollup from '../components/Scrollup/Scrollup'

function CollectionDetailPage() {
    return (
        <div className="main">
            <Explorer showMethod={1}/>
            <Footer />
            <ModalSearch />
            <Scrollup />
        </div>
    )
}

export default CollectionDetailPage