import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { useWeb3React } from '@web3-react/core'
import Modal from '@mui/material/Modal';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Toaster } from "react-hot-toast";

import { connectors, connectorLocalStorageKey } from './utils/connectors'
import { useEagerConnect } from "./hooks/useEagerConnect"
import { useInactiveListener } from "./hooks/useInactiveListener"
import { useAxios } from "./hooks/useAxios";
import { getErrorMessage } from "./utils/ethereum";
import { getUser, useAuthDispatch, useAuthState } from "./context/authContext";
// importing all the themes
import ExplorerPage from "./themes/ExplorerPage";
import WalletConnect from "./themes/wallet-connect";
import CreateItemPage from "./themes/CreateItemPage";
import Contact from "./themes/contact";
import Collections from "./themes/collections";
import Header from "./components/Header/Header";
import Home from "themes/Home";
import './app.scss'
import Activity from "themes/ActivityPage";
import ItemDetailsPage from "themes/ItemDetailsPage";
import ProfilePage from "themes/ProfilePage";
import CollectionDetailPage from "themes/CollectionDetailPage";
import CreateCollection from "themes/createCollection";
import EditProfile from "themes/EditProfile";
import ImportPage from "themes/ImportPage";
import SideBarMenu from "components/Sidebar/SideBarMenu";

function App() {
  const [errorModalOpen, setErrorModalOpen] = useState(null);
  const [networkError, setNetworkError] = useState(null);

  function getModalStyle() {
    const top = 50
    const left = 50
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 300,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 4, 3),
    },
  }));

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  useAxios();

  const { account, activate, active, connector, chainId } = useWeb3React();
  const connectToProvider = (connector) => {
    activate(connector)
  }

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // mount only once or face issues :P
  const [triedEager, triedEagerError] = useEagerConnect()
  const { activateError } = useInactiveListener(!triedEager || !!activatingConnector)

  // handling connection error
  if ((triedEagerError || activateError) && errorModalOpen === null) {
    const errorMsg = getErrorMessage(triedEagerError || activateError);
    setNetworkError(errorMsg);
    setErrorModalOpen(true);
  }

  const dispatch = useAuthDispatch();
  const { user } = useAuthState();
  let newVariable = process.env.REACT_APP_NETWORK_ID;
  useEffect(() => {
    if (active && account && chainId === parseInt(newVariable, 10)) {
      getUser(dispatch, account)
    }
  }, [active, account, dispatch, chainId])

  const closeErrorModal = () => {
    window.localStorage.setItem(connectorLocalStorageKey, connectors[0].key);
    window.location.reload();
  }
  const [expanded, setExpanded] = useState(false)
  return (
    <React.Fragment>
      <Router>
        <Toaster
          position="top-center"
          toastOptions={{
            success: {
              duration: 3000,
              style: {},
            },
            error: {
              duration: 3000,
              style: {},
            },
          }}
        />
        <Header expanded = {expanded} setExpanded = {setExpanded}/>
        <SideBarMenu expanded = {expanded} setExpanded = {setExpanded}/>
        <Switch>
          <Route exact path="/" render={() => <Home/>} />
          <Route exact path="/explore" render={(props) => <ExplorerPage showMethod={0} {...props}></ExplorerPage>} />
          <Route exact path="/collections" render={(props) => <Collections filter={0} {...props}></Collections>} />
          <Route exact path="/collections/:name" render={() => <CollectionDetailPage/>} />
          <Route exact path="/item/:itemCollection/:tokenId" render={(props) => <ItemDetailsPage {...props}></ItemDetailsPage>} />
          <Route exact path="/activity" render={(props) => <Activity {...props}></Activity>} />

          <Route exact path="/wallet-connect" render={(props) => <WalletConnect {...props} connectToProvider={connectToProvider}></WalletConnect>} />
          <Route exact path="/create" render={(props) => <CreateItemPage {...props}></CreateItemPage>} />
          <Route exact path="/collection/create" render={(props) => <CreateCollection {...props}></CreateCollection>} />
          <Route exact path="/collection/edit/:name" render={(props) => <CreateCollection {...props}></CreateCollection>} />
          <Route exact path="/profile/:address" render={() => <ProfilePage/>} />
          <Route exact path="/myCollections" render={(props) => <Collections filter={1} {...props}></Collections>} />
          <Route exact path="/edit-profile" render={() => <EditProfile/>} />
          <Route exact path="/myItems" render={(props) => <ExplorerPage showMethod={2} {...props}></ExplorerPage>} />
          <Route exact path="/import" render={() => <ImportPage/>} />
          <Route exact path="/contact" render={(props) => <Contact {...props}></Contact>} />
        </Switch>
      </Router>

      <Modal
        open={!!errorModalOpen && !active}
        onClose={(event : any, reason : any) => {
          if (reason === "backdropClick") {
            return false;
          }
          if (reason === "escapeKeyDown") {
            return false;
          }
          setErrorModalOpen(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={`${classes.paper} modal-div`}>
          <p>{networkError}</p>
          <Button className="" onClick={closeErrorModal} variant="contained" color="primary">Close</Button>
        </div>

      </Modal>

    </React.Fragment>
  );
}

export default App;