import './leftFilter.scss'
import Expand from "react-expand-animated";
import { useEffect, useState } from 'react';
import { rarityFilter } from 'utils/options';

type PropsType = {
    setTokenID?: any
    setIsMyNFT?: any
    setSaleType?: any
    setMinPrice?: any
    setMaxPrice?: any
    setCollectionAddress?: any,
    setSelectedRarityList?: any,
    tokenID?: number,
    isMyNFT?: boolean,
    saleType?: number,
    collectionList?: any
    collectionAddress?: string[],
    minPrice?: number,
    maxPrice?: number,
    displayMethod?: number,
    traitList?: any[]
    selectedTraitList?: string[][]
    handleTrait?: any,
    selectedRarityList?: number[],
    colAddr?: any,
    loginStatus?: boolean,
}

export default function LeftFilter(
    {
        setTokenID,
        setIsMyNFT,
        setSaleType,
        setMinPrice,
        setMaxPrice,
        setCollectionAddress,
        setSelectedRarityList,
        tokenID,
        isMyNFT,
        saleType,
        collectionList,
        collectionAddress,
        minPrice,
        maxPrice,
        displayMethod,
        traitList,
        handleTrait,
        selectedTraitList,
        selectedRarityList,
        colAddr,
        loginStatus
    }: PropsType) {
    const styles = {
        open: { width: "100%" },
        close: { width: "100%" }
    };
    const transitions = ["height", "opacity", "background"];

    const onClear = () => {
        setTokenID(-1)
        setSaleType(0)
        setCollectionAddress([]);
        setSelectedRarityList([]);
        handleTrait([]);
        setMaxPrice(0)
        setMinPrice(0)
    }
    const [colIdx, setColIdx] = useState<number[]>([])
    const [isExpand, setIsExpand] = useState(true);
    const [isExpand1, setIsExpand1] = useState(false);

    const handleChange = (e) => {
        const idx = collectionAddress.indexOf(e.target.value);
        if (idx > -1) collectionAddress.splice(idx, 1) //isExist
        else collectionAddress.push(e.target.value);
        setCollectionAddress([...collectionAddress]);
    }

    const handleRarityChange = (rarity) => {
        const idxOf = selectedRarityList?.indexOf(rarity);
        if (idxOf > -1) selectedRarityList?.splice(idxOf, 1);
        else selectedRarityList?.push(rarity);
        console.log(selectedRarityList)
        setSelectedRarityList([...selectedRarityList]);
    }

    const handleTraitsChange = (trait_type, idx) => {
        let _trait_list = [];
        for (var i = 0; i < selectedTraitList.length; i++) {
            _trait_list.push([]);
            for (var j = 0; j < selectedTraitList[i].length; j++) {
                _trait_list[i].push(selectedTraitList[i][j]);
            }
        }
        const idxOf = _trait_list[idx]?.indexOf(trait_type);
        if (idxOf > -1) _trait_list[idx]?.splice(idxOf, 1);
        else _trait_list[idx]?.push(trait_type);
        handleTrait(_trait_list);
    }
    const handExpand = (val: number) => {
        const idx = colIdx.indexOf(val);
        if (idx > -1) colIdx.splice(idx, 1) //isExist
        else colIdx.push(val);
        setColIdx([...colIdx]);
    }
    return (
        <div className='explorer-leftFilter'>
            <div className="filterTop">
                <h3>Filter</h3>
                <div className="clear" onClick={onClear}>Clear All</div>
            </div>

            <div className="searchFilter">
                <div className="header">
                    <h5>Search by #TokenID</h5>
                </div>
                <div className="search">
                    <span>
                        <input
                            type="number"
                            id="name"
                            name="name"
                            value={tokenID >= 0 ? tokenID : ''}
                            onChange={(e) => !isNaN(parseFloat(e.target.value)) ? setTokenID(parseFloat(e.target.value)) : setTokenID(-1)}
                            placeholder="#TokenID"
                        />

                        <button><i className="fas fa-search"></i></button>
                    </span>

                </div>
            </div>
            <div className="priceFilter mb-3">
                <div className="filterHeader">
                    <h6>Price Range</h6>
                </div>
                <div className="search">
                    <span>
                        <input
                            type="number"
                            id="cc1"
                            name="image"
                            onChange={(e) => !isNaN(parseFloat(e.target.value)) ? setMinPrice(parseFloat(e.target.value)) : setMinPrice(0)}
                            placeholder='Min'
                            value={minPrice > 0 && minPrice}
                        />
                        <p>to</p>
                        <input
                            type="number"
                            id="cc2"
                            name="video"
                            onChange={(e) => !isNaN(parseFloat(e.target.value)) ? setMaxPrice(parseFloat(e.target.value)) : setMaxPrice(0)}
                            placeholder='Max'
                            value={maxPrice > 0 && maxPrice}
                        />
                    </span>
                </div>
            </div>
            {
                displayMethod !== 2 && loginStatus && <form action="" className='filterForm'>
                    <span>
                        <input
                            checked={isMyNFT}
                            type="checkbox" id="my_nft"
                            onChange={(e) => setIsMyNFT(!isMyNFT)}
                            name="fav_language"
                            className="checkbox"
                        />
                        <label htmlFor="my_nft">My NFTs</label>
                    </span>
                </form>
            }
            {
                displayMethod === 1 && colAddr === "0xc6766f4793899b75970089c1e9dc80e4e1ef0107" && <>
                    <div className="filterHeader hover" onClick={() => setIsExpand(true)}>
                        <h6>Rarity</h6>
                        <span
                            style={{ transition: 'all 0.3s ease', transform: `${isExpand ? "rotate(90deg)" : "rotate(0deg)"}` }}
                        ><i className="fas fa-angle-right"></i></span>
                    </div>
                    <Expand
                        open={isExpand}
                        duration={300}
                        styles={styles}
                        transitions={transitions}
                    >
                        <form action="" className='filterForm'>
                            {rarityFilter?.map((rarity, key) => (
                                <span key={key}>
                                    <input
                                        type="checkbox"
                                        id={`rarity_${key}`}
                                        name={rarity.title}
                                        value={rarity.value}
                                        onChange={(e) => handleRarityChange(rarity.value)}
                                        checked={selectedRarityList?.indexOf(rarity.value) > -1}
                                    />
                                    <label htmlFor={`rarity_${key}`}>{rarity.title}{rarity.value === -1 ? "" : " (" + rarity.value + ")"}</label>
                                </span>
                            ))}
                        </form>
                    </Expand>
                </>
            }

            <div className="filterHeader hover" onClick={() => setIsExpand(true)}>
                <h6>Sale Type</h6>
                <span
                    style={{ transition: 'all 0.3s ease', transform: `${isExpand ? "rotate(90deg)" : "rotate(0deg)"}` }}
                ><i className="fas fa-angle-right"></i></span>
            </div>
            <Expand
                open={isExpand}
                duration={300}
                styles={styles}
                transitions={transitions}
            >
                <form action="" className='myform'>
                    <span>
                        <input
                            type="radio" id="one"
                            onChange={(e) => setSaleType(parseFloat(e.target.value))}
                            value="2"
                            name="fav_language"
                            className="radio"
                            defaultChecked={saleType === 2}
                        />
                        <label htmlFor="one">All</label>
                    </span>
                    <span>
                        <input
                            defaultChecked={saleType === 0}
                            type="radio" id="two"
                            value="0"
                            onChange={(e) => setSaleType(parseFloat(e.target.value))}
                            name="fav_language"
                            className="radio"
                        />
                        <label htmlFor="two">For Sale</label>
                    </span>
                    <span>
                        <input
                            defaultChecked={saleType === 1}
                            type="radio"
                            id="three"
                            value="1"
                            onChange={(e) => setSaleType(parseFloat(e.target.value))}
                            name="fav_language"
                            className="radio"
                        />
                        <label htmlFor="three">Not Listed</label>
                    </span>
                </form>

            </Expand>
            {displayMethod !== 1 ?
                <>
                    <div className="filterHeader hover" onClick={() => setIsExpand1(!isExpand1)}>
                        <h6>Collections List</h6>
                        <span
                            style={{ transition: 'all 0.3s ease', transform: `${isExpand1 ? "rotate(90deg)" : "rotate(0deg)"}` }}
                        ><i className="fas fa-angle-right"></i></span>
                        {/* <i className="fas fa-angle-down"></i> */}
                    </div>
                    <Expand
                        open={isExpand1}
                        duration={300}
                        styles={styles}
                        transitions={transitions}
                    >
                        <form action="" className='filterForm'>
                            {collectionList?.map((collection, key) => (
                                <span key={key}>
                                    <input
                                        type="checkbox"
                                        id={`one_${key}`}
                                        name={collection.address}
                                        value={collection.address}
                                        onChange={(e) => handleChange(e)}
                                        checked={collectionAddress.indexOf(collection.address) > -1}
                                    />
                                    <label htmlFor={`one_${key}`}>{collection.name}</label>
                                </span>
                            ))}
                        </form>

                    </Expand>


                </> :
                <>
                    {traitList.map((d, idx) => (
                        <>
                            <div key={`a_${idx}`} className="filterHeader hover" onClick={() => handExpand(idx)}>
                                <h6>{Object.keys(d)[0]}</h6>
                                <span
                                    style={{ transition: 'all 0.3s ease', transform: `${colIdx.indexOf(idx) > -1 ? "rotate(90deg)" : "rotate(0deg)"}` }}
                                ><i className="fas fa-angle-right"></i></span>
                            </div>
                            <Expand
                                key={`e_${idx}`}
                                open={colIdx.indexOf(idx) > -1}
                                duration={300}
                                styles={styles}
                                transitions={transitions}
                            >
                                <form action="" className='filterForm1'>
                                    {Object.keys(d[Object.keys(d)[0]]).map((item, key) => (
                                        <span key={`span_${idx}_${key}`}>
                                            <p>{Object.values(d[Object.keys(d)[0]])[key]}</p>
                                            <input
                                                type="checkbox"
                                                id={`checkbox_${idx}_${key}`}
                                                name={`${idx}`}
                                                value={item}
                                                onChange={(e) => handleTraitsChange(item, idx)}
                                                checked={selectedTraitList[idx] && selectedTraitList[idx]?.indexOf(item) > -1}
                                            />
                                            <label htmlFor={`checkbox_${idx}_${key}`}>{item}</label>
                                        </span>
                                    ))}
                                </form>

                            </Expand>
                        </>

                    ))}
                </>
            }


        </div>
    )
}
