import EditAuthor from '../components/Author/EditAuthor'
import Footer from '../components/Footer/Footer'
import ModalSearch from '../components/Modal/ModalSearch'
import Scrollup from '../components/Scrollup/Scrollup'
function EditProfile() {
    return (
        <div className="main">
            {/* <Breadcrumb title="Edit Profile" subpage="profile" page="edit-profile" /> */}
            <EditAuthor />
            <Footer />
            <ModalSearch />
            <Scrollup />
        </div>
    )
}

export default EditProfile