import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import Scrollup from '../components/Scrollup/Scrollup';
import CreateItem from 'components/Create/CreateItem';

export default function CreateItemPage(props) {
    return (
        <div className="main">
            <Breadcrumb title="Create Item" page="Create" />
            <CreateItem {...props} />
            <Footer />
            <ModalSearch />
            <Scrollup />
        </div>
    );
}