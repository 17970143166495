import MyProfile from '../components/Author/MyProfile'
import Footer from '../components/Footer/Footer'

import ModalSearch from '../components/Modal/ModalSearch'
import Scrollup from '../components/Scrollup/Scrollup'

function ProfilePage() {
    return (
        <div className="main">
            <MyProfile />
            <Footer />
            <ModalSearch />
            <Scrollup />
        </div>
    )
}

export default ProfilePage