import "./PropertiesTable.scss";
import Accordion from "react-bootstrap/Accordion";
export default function PropertiesTable({ item }) {
  return (
    <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <br />
      <Accordion.Item eventKey="0">
        <Accordion.Body>
          <div>
            {item?.properties && item?.properties.length > 0 ? (
              <div>
                {/* <PropertiesTable item={item} /> */}
                <div>
                  {item?.properties.length > 0 ? (
                    <div className="properties-stats-levels-container col-md-12 justify-content-center">
                      {item.properties.map((property, key) => {
                        return (
                          <div className="properties-stats-levels  col-lg-3 col-md-3" key={key}>
                            <span>{property.trait_type}</span>
                            <span className="properties-stats-levels-span">
                              {property.value.length > 16 ? property.value.substring(0, 12) + "..." : property.value}
                            </span>
                            {property.percent && <span className="properties-stats-levels-span-last">{property.percent}% have this trait</span>}
                          </div>
                        );
                      })}
                    </div>
                  ) : undefined}
                </div>
              </div>
            ) : (
              <div className="no-levels-yet">
                <p>No Properties yet</p>
              </div>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />
    </Accordion>
  );
};
