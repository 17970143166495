import React from 'react';
import { connectors, connectorLocalStorageKey } from '../../utils/connectors'
import { useWeb3React } from '@web3-react/core';
import { useHistory } from 'react-router-dom';

function Wallet(props) {
    const { active } = useWeb3React();
    let history = useHistory();
    const onWalletConnect = (walletData) => {
        props.connectToProvider(walletData.connectorId);
        window.localStorage.setItem(connectorLocalStorageKey, walletData.key);
        if (history.action !== 'POP') history.goBack();
    }
    return (
        <section className="wallet-connect-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        {/* Intro */}
                        <div className="intro text-center">
                            <span>Wallet Connect</span>
                            <h3 className="mt-3 mb-0">Connect your Wallet</h3>
                            <p>WalletConnect is an open protocol to communicate securely between Wallets and Dapps (Web3 Apps).</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center items">
                        {
                            connectors.map((walletData, key) => {
                                return (
                                    <div key={key} onClick={()  => active ? null : onWalletConnect(walletData)} className="col-12 col-md-6 col-lg-4 item"> 
                                        <div className="card single-wallet">
                                            <div className="d-block text-center" to="/login">
                                                <img className="avatar-lg" src={walletData?.img} alt="" />
                                                <h4 className="mb-0">{walletData?.title}</h4>
                                                <p>{walletData?.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    
                </div>
            </div>
        </section>
    );

}

export default Wallet;