import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { truncateWalletString } from "../../utils";
import { connectors, connectorLocalStorageKey } from "../../utils/connectors";
import './style.scss'
const ModalMenu = () => {
  const { connector, library, account, active, chainId, deactivate } = useWeb3React();
  const [loginStatus, setLoginStatus] = useState(false);
  let newVariable = process.env.REACT_APP_NETWORK_ID;
  useEffect(() => {
    const isLoggedin = account && active && chainId === parseInt(newVariable, 10);
    setLoginStatus(isLoggedin);
  }, [connector, library, account, active, chainId, newVariable]);



  const [user, setUser] = useState(undefined);

  useEffect(() => {
    if (loginStatus && !user) {
      axios.get(`/api/user/${account}`)
        .then(async res => {
          setUser(res.data.user);
        })
        .catch(err => {
          setUser(undefined);
        })
    }
  }, [loginStatus, user, account]);


  const signOut = () => {
    connectors.map((connector) => {
      deactivate();
    })
    window.localStorage.setItem(connectorLocalStorageKey, "");
    window.location.href = "/";
  }
    return (
        <div id="menu" className="modal fade p-0">
            <div className="modal-dialog dialog-animated">
                <div className="modal-content h-100">
                    <div className="modal-header" data-dismiss="modal">
                        Menu <i className="far fa-times-circle icon-close" />
                    </div>
                    <div className="menu modal-body">
                        <div className="row w-100">
                        
                            {/* <div className="items p-0 col-12 text-center" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalMenu;