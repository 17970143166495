import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import getNodeUrl from "./getRpcUrl";
import { currentNetwork } from "./index";
const POLLING_INTERVAL = 12000
export const injectedConnector = new InjectedConnector({
  supportedChainIds: [+currentNetwork],
});

export function getConnector(connectorId) {
  switch (connectorId) {
    case "injectedConnector":
      return injectedConnector;
    case "walletconnect":
      return walletconnect;
    default:
      return injectedConnector;
  }
}

export const walletconnect = new WalletConnectConnector({
  rpc: {[+currentNetwork]: getNodeUrl()},
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL
});

export const connectorsByName = {
  Injected: injectedConnector,
  WalletConnect: walletconnect,
};

export const connectors = [
  {
    id: 0,
    img: "/img/metamask.png",
    title: "MetaMask",
    content: "A browser extension with great flexibility. The web's most popular wallet",
    connectorId: injectedConnector,
    key: "injectedConnector",
  },
  {
    id: 1,
    img: "/img/walletconnect.png",
    title: "WalletConnect",
    content: "Pair with Trust, Argent, MetaMask & more. Works from any browser, without an extension",
    connectorId: walletconnect,
    key: "walletconnect",
  }
];

export const connectorLocalStorageKey = "connectorId";
