import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import Scrollup from '../components/Scrollup/Scrollup';
import ImportCollection from 'components/Import/ImportCollection';

export default function ImportPage()  {
    return (
        <div className="main">
            <Breadcrumb title="Import Collection" subpage="" page="Import Collection" />
            <ImportCollection />
            <Footer />
            <ModalSearch />
            <Scrollup />
        </div>
    );
}