import pinataSDK from "@pinata/sdk";
const pinata = pinataSDK(
  "7965f61f01862b900363",
  "18c4dca106b1def13eb51a522a6382142b61c3b081a0d0f050633f471e4b0112"
);

export const getIpfsHash = async (data) => {
  const result = await pinata.pinJSONToIPFS(data);
  const hash = result.IpfsHash;
  return hash;
};

export const getIpfsHashFromFile = async (file) => {
  return new Promise((resolve, reject) => {
    var headers = new Headers();
    headers.append("pinata_api_key", "7965f61f01862b900363");
    headers.append(
      "pinata_secret_api_key",
      "18c4dca106b1def13eb51a522a6382142b61c3b081a0d0f050633f471e4b0112"
    );
    var formdata = new FormData();
    formdata.append("file", file);
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: formdata,
    };
    fetch("https://api.pinata.cloud/pinning/pinFileToIPFS", requestOptions)
      .then((r) => r.json())
      .then((r) => {
        resolve(r.IpfsHash);
      })
      .catch((error) => reject(error));
  });
};
