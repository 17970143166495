import { Contract } from "@ethersproject/contracts";
import EpicGalleryNFTAbI from "../contracts/EpicGalleryNFT.json";
import EpicGalleryMarketAbI from "../contracts/EpicGalleryMarket.json";
import EpicGalleryAuctionAbI from "../contracts/EpicGalleryAuction.json";

export const currentNetwork = process.env.REACT_APP_NETWORK_ID;

export const CONTRACTS_BY_NETWORK = {
  [currentNetwork]: {
    EpicGalleryNFT: {
      address: "0x8292844fa6a2eba622ddc84ce09a60a4d91b668c",
      abi: EpicGalleryNFTAbI,
    },
    EpicGalleryMarket: {
      address: "0x864f57d94cc4f5a12f0d07894a9c4fcb31c4800a",
      abi: EpicGalleryMarketAbI,
    },
    EpicGalleryAuction: {
      address: "0x2825c27ffe5acc2d381eddb03b08bcb44bcc529e",
      abi: EpicGalleryAuctionAbI,
    },
  },
};

export const baseApiUrl = process.env.REACT_APP_API_URL;

export function getContractInfo(name, chainId) {
  if (!chainId) chainId = currentNetwork;

  const contracts = CONTRACTS_BY_NETWORK?.[chainId];
  if (contracts) {
    return contracts?.[name];
  } else {
    return null;
  }
}

export function getContractObj(name, chainId, provider) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(info.address, info.abi, provider);
}

export function getCollectionContract(
  address,
  chainId,
  provider
) {
  const info = getContractInfo("EpicGalleryNFT", chainId);
  return !!info && new Contract(address, info.abi, provider);
}

export function truncateWalletString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(0, 7);
  const endStr = walletAddress.substring(lengthStr - 7, lengthStr);
  return startStr + "..." + endStr;
}

export function truncateString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(2, 10);
  const endStr = walletAddress.substring(lengthStr - 5, lengthStr);
  return startStr.toUpperCase() + "..." + endStr.toUpperCase();
}

export function truncateActivityString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(2, 6);
  const endStr = walletAddress.substring(lengthStr - 5, lengthStr);
  return startStr.toUpperCase() + "..." + endStr.toUpperCase();
}

export function truncateHashString(txhash) {
  if (!txhash) return txhash;
  const lengthStr = txhash.length;
  const startStr = txhash.substring(0, 10);
  const endStr = txhash.substring(lengthStr - 10, lengthStr);
  return startStr + "..." + endStr;
  
}

export const shorter = (str) =>
  str?.length > 8 ? str.slice(0, 6) + "..." + str.slice(-4) : str;
