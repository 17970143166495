export const CATEGORIES = [
  { value: "all", title: "All" },
  { value: "art", title: "Art" },
  { value: "music", title: "Music" },
  { value: "virtual worlds", title: "Virtual Worlds" },
  { value: "sports", title: "Sports" },
  { value: "utility", title: "Utility" },
]
export const SALE = ["For Sale", "Not Listed", "All Items"];
export const sortBy = [
  "Price: Low To High",
  "Price: High To Low", 
  "Newest",
  "Oldest"
];
export const sortCollectionDetailBy = [
  "Price: Low To High",
  "Price: High To Low", 
  "Newest",
  "Oldest",
  "Token Id",
];

export const rarityFilter = [
  { value: 0, title: "Epic"},
  { value: 1, title: "Legendary"},
  { value: 2, title: "Rare"},
  { value: 3, title: "Uncommon"},
  { value: 4, title: "Common"},
]
export const volumeSortBy = [
  "Volume: High To Low",
  "Volume: Low To High",
  "Newest",
  "Oldest"
];
export const volumeBy = [ "All Volume", "By 24h Volume", "By 72h Volume"];
export const createdBy = ["Newest", "Oldest"];
export const EVENT_TYPES = ["Listed", "Delisted", "Transferred", "Minted", "Purchased"];
