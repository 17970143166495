import ReactDOM from 'react-dom';
import './index.css';
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import App from './App';
import { AuthProvider } from './context/authContext';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </Web3ReactProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();



