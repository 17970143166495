import React from "react";
import ExplorerComponent from "../components/Explore/Explorer";
import Footer from "../components/Footer/Footer";
import ModalSearch from "../components/Modal/ModalSearch";
import Scrollup from "../components/Scrollup/Scrollup";

function ExplorerPage(props) {
    return (
        <div className="main">
            {/* <Breadcrumb title="Explore" page="explore" /> */}
            <ExplorerComponent {...props} />
            <Footer />
            <ModalSearch />
            <Scrollup />
        </div>
    );
}

export default ExplorerPage;
